<template>
    <EmployeeTrackRequest></EmployeeTrackRequest>
</template>
<script>
    import EmployeeTrackRequest from '@/components/EmployeeTrackApprovedRequest.vue'
    export default {
        name: 'employee-track-approved-request',
        components: {
            EmployeeTrackRequest
        }
    }
</script>