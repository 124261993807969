<template>
  <div class="w-full h-screen bg-white overflow-x-hidden">
    <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
    <div>
      <Sidebar class="sidebar"/>
      <div :class="[!expandBody ? 'main px-5 space-y-2 margin mb-16' : 'main px-5 space-y-2 mb-16']">
        <!-- the top route and username grid -->
        <div class="justify-between flex pt-5 text-size-md font-medium text-gray-500 w-full">
          <span class=" text-emerald-500" >> Home > Create Liquidation Step 2</span>
          <span v-if="employee !== null">{{ employee.firstname }} {{ employee.surname }} </span>
        </div>
        <!-- the requests charts -->
        <div class="grid gap-6 grid-rows-1 grid-cols-1">
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg mt-4 bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-6 mb-4 items-center space-x-1">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,24H3a3,3,0,0,1-3-3V0H2V21a1,1,0,0,0,1,1H24Z"/><rect x="14" y="11" width="2" height="9"/><rect x="6" y="11" width="2" height="9"/><rect x="18" y="6" width="2" height="14"/><rect x="10" y="6" width="2" height="14"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m12,12c3.309,0,6-2.691,6-6S15.309,0,12,0s-6,2.691-6,6,2.691,6,6,6Zm0-10c2.206,0,4,1.794,4,4s-1.794,4-4,4-4-1.794-4-4,1.794-4,4-4Zm9,17v5h-2v-5c0-1.654-1.346-3-3-3h-2l-1.333,2,1.333,6h-4l1.333-6-1.333-2h-2c-1.654,0-3,1.346-3,3v5h-2v-5c0-2.757,2.243-5,5-5h8c2.757,0,5,2.243,5,5Z"/>
              </svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M18.4,7.379a1.128,1.128,0,0,1-.769-.754h0a8,8,0,1,0-15.1,5.237A1.046,1.046,0,0,1,2.223,13.1,5.5,5.5,0,0,0,.057,18.3,5.622,5.622,0,0,0,5.683,23H11a1,1,0,0,0,1-1h0a1,1,0,0,0-1-1H5.683a3.614,3.614,0,0,1-3.646-2.981,3.456,3.456,0,0,1,1.376-3.313A3.021,3.021,0,0,0,4.4,11.141a6.113,6.113,0,0,1-.073-4.126A5.956,5.956,0,0,1,9.215,3.05,6.109,6.109,0,0,1,9.987,3a5.984,5.984,0,0,1,5.756,4.28,2.977,2.977,0,0,0,2.01,1.99,5.934,5.934,0,0,1,.778,11.09.976.976,0,0,0-.531.888h0a.988.988,0,0,0,1.388.915c4.134-1.987,6.38-7.214,2.88-12.264A6.935,6.935,0,0,0,18.4,7.379Z"/><path d="M18.707,16.707a1,1,0,0,0,0-1.414l-1.586-1.586a3,3,0,0,0-4.242,0l-1.586,1.586a1,1,0,0,0,1.414,1.414L14,15.414V23a1,1,0,0,0,2,0V15.414l1.293,1.293a1,1,0,0,0,1.414,0Z"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m17,0H7C4.243,0,2,2.243,2,5v14c0,2.757,2.243,5,5,5h10c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5ZM7,2h10c1.654,0,3,1.346,3,3H4c0-1.654,1.346-3,3-3Zm10,20H7c-1.654,0-3-1.346-3-3V7h16v12c0,1.654-1.346,3-3,3Zm0-11c0,.553-.448,1-1,1h-8c-.552,0-1-.447-1-1s.448-1,1-1h8c.552,0,1,.447,1,1Zm-4,4c0,.553-.448,1-1,1h-4c-.552,0-1-.447-1-1s.448-1,1-1h4c.552,0,1,.447,1,1Z"/></svg> -->
              <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m13,5v2H5v-2h8Zm-7,6h6v-2h-6v2Zm17,4.764v5.236c0,1.654-1.346,3-3,3H1V0h17c1.654,0,3,1.346,3,3v8.764l2,4ZM3,22h12V2H3v20Zm18-5.764l-2-4V3c0-.551-.448-1-1-1h-1v20h3c.552,0,1-.449,1-1v-4.764Z"/>
              </svg>
              <p class="text-left text-size-md font-medium text-gray-600">Create Liquidation - Expense Report</p>
            </div>
            <!-- The activity creation steps -->
             <!-- {{ liquidationCurrencyAndFunding }} -->
            <div class="flex items-center col-span-full mx-4 mt-5 pr-2">
              <span class="ml-4 mr-0.5 cursor-pointer text-size-sm font-medium bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">1</span>
              <div class="flex-auto cursor-pointer text-size border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="ml-0.5 mr-0.5 animate-pulse cursor-pointer text-size-sm font-medium bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">2</span>
              <div class="flex-auto cursor-pointer animate-pulse border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="mx-0.5 cursor-pointer text-size-sm font-medium ring-emerald-600 ring-2 text-gray-500 py-0 px-1">3</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="cursor-pointer mx-0.5 text-size-sm font-medium ring-emerald-600 ring-2 text-gray-500 py-0 px-1">4</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="cursor-pointer mx-0.5 text-size-sm ring-emerald-600 font-medium ring-2 text-gray-500 py-0 px-1">5</span>
            </div>
            <!--  chart -->
            <div class="ml-8 pb-8 mt-4">
              <div
                class="ring-1 py-2 text-left cursor-pointer ring-gray-200 shadow-md mr-10 pl-1 space-y-4"
              >
                <!-- add details & reset buttons -->
                <div class="text-center mt-2">
                  <button @click="resetExpenseReport()" class="shadow inline-block box-shadow text-size-sm px-20 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">Reset</button>
                  <button @click="openAddBudgetModal()" class="shadow inline-block box-shadow text-size-sm px-16 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">Add Details</button>
                </div>
                <!-- the expense report table -->
                <div class="px-4 pb-6 overflow-x-scroll">
                  <table id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                      <thead>
                        <tr class=" bg-emerald-600 text-size-xs pointer-events-none" style="">
                          <td class="py-3 px-2 text-left text-white font-bold">Date</td>
                          <td class="py-3 px-2 border-l text-left text-white font-bold">Description</td>
                          <td class="py-3 px-2 border-l text-left text-white font-bold">Project Name(s)</td>
                          <td class="py-3 px-2 border-l text-left text-white font-bold">Currency</td>
                          <td class="py-3 px-2 border-l text-left text-white font-bold">Hotel</td>
                          <td class="py-3 px-2 border-l text-left text-white font-bold">MI&E</td>
                          <td class="py-3 px-2 border-l text-left text-white font-bold">Other</td>
                          <td class="py-3 px-2 border-l text-left text-white font-bold">Notes</td>
                          <td class="py-3 px-2 border-l text-center text-white font-bold">Action</td>
                        </tr>
                      </thead>
                      <tbody v-if="showExpenseReport">
                        <tr v-for="(data, i) in expenseReportData" :key="i" class=" text-gray-600 text-size-md" style="">
                          <td class="py-3 px-2 border text-left">{{ formatData(data.date) }}</td>
                          <td class="py-3 px-2 border text-left">{{ data.description }}</td>
                          <td class="py-3 px-2 border text-left">{{ formatData(data.project) }}</td>

                          <td v-if="i === expenseReportData.length - 1" class="py-3 px-2 border text-left font-medium">{{ data.currency }}</td>
                          <td v-else class="py-3 px-2 border text-left">{{ data.currency }}</td>

                          <td v-if="i === expenseReportData.length - 1" class="py-3 px-2 border text-left font-medium">{{ formatData(data.hotel) }}</td>
                          <td v-else class="py-3 px-2 border text-left">{{ formatData(data.hotel) }}</td>
                          
                          <td v-if="i === expenseReportData.length - 1" class="py-3 px-2 border text-left font-medium">{{ formatData(data.mie) }}</td>
                          <td v-else class="py-3 px-2 border text-left">{{ formatData(data.mie) }}</td>
                          
                          <td v-if="i === expenseReportData.length - 1" class="py-3 px-2 border text-left font-medium">{{ formatData(data.other) }}</td>
                          <td v-else class="py-3 px-2 border text-left">{{ formatData(data.other) }}</td>
                          
                          <td v-if="i !== expenseReportData.length - 1" class="py-3 px-2 border text-left font-bold">{{ formatData(data.notes) }}</td>
                          <td v-else class="py-3 px-2 border text-left font-medium">{{ formatData(data.notes) }}</td>
                          
                          <td class="py-3 px-2 border text-center flex">
                            <div v-if="i !== expenseReportData.length - 1" class="flex mx-auto space-x-4">
                              <svg @click="deleteItem(i)" class="h-4 w-4 text-red-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.707,15.293-1.414,1.414L12,13.414,8.707,16.707,7.293,15.293,10.586,12,7.293,8.707,8.707,7.293,12,10.586l3.293-3.293,1.414,1.414L13.414,12Z"/></svg>
                              <!-- <svg @click="loadEditBudgetModal(i)" class="h-5 w-5 text-emerald-400 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m21,0H3C1.346,0,0,1.346,0,3v21h24V3c0-1.654-1.346-3-3-3Zm-2.879,10.621l-7.878,7.879h-4.243v-4.242l7.879-7.879c1.133-1.133,3.109-1.133,4.243,0,1.169,1.17,1.169,3.072,0,4.242Zm-3.871,1.043l-4.836,4.836h-1.415v-1.414l4.836-4.836,1.414,1.414Zm2.457-3.871c.39.39.39,1.024,0,1.414l-1.043,1.043-1.414-1.414,1.043-1.043c.377-.379,1.036-.379,1.414,0Z"/></svg> -->
                              <svg @click="loadEditBudgetModal(i)" class="h-4 w-4 text-emerald-600 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M23,24H5c-.55,0-1-.45-1-1s.45-1,1-1H23c.55,0,1,.45,1,1s-.45,1-1,1ZM2.48,11.89c-.31,.73-.48,1.53-.48,2.34v4.35l-1.71,1.71c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29l1.71-1.71H7.76c.81,0,1.61-.17,2.34-.48L2.48,11.89ZM20.91,7.83l-8.67,10.15c-.13,.15-.27,.29-.42,.42L3.59,10.17c.13-.14,.27-.28,.42-.41L14.17,1.1c1.78-1.54,4.47-1.45,6.14,.21l.39,.39c1.66,1.66,1.76,4.36,.22,6.13Z"/></svg>
                            </div>
                            <div v-else class="flex mx-auto space-x-4">
                              <svg class="h-4 w-4 text-white fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.707,15.293-1.414,1.414L12,13.414,8.707,16.707,7.293,15.293,10.586,12,7.293,8.707,8.707,7.293,12,10.586l3.293-3.293,1.414,1.414L13.414,12Z"/></svg>
                              <svg class="h-4 w-4 text-white fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m21,0H3C1.346,0,0,1.346,0,3v21h24V3c0-1.654-1.346-3-3-3Zm-2.879,10.621l-7.878,7.879h-4.243v-4.242l7.879-7.879c1.133-1.133,3.109-1.133,4.243,0,1.169,1.17,1.169,3.072,0,4.242Zm-3.871,1.043l-4.836,4.836h-1.415v-1.414l4.836-4.836,1.414,1.414Zm2.457-3.871c.39.39.39,1.024,0,1.414l-1.043,1.043-1.414-1.414,1.043-1.043c.377-.379,1.036-.379,1.414,0Z"/></svg>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                  </table>
                </div>
              </div>
              <div class="w-full flex text-center mx-auto pt-1 mt-4">
                <div class="flex-1">
                  <button
                    @click="back"
                    class="shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-500  text-white font-medium leading-tight transform focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                      Back
                  </button>
                  <button
                    @click="next"
                    class="shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-500  text-white font-medium leading-tight transform focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                      Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- the loader modal -->
    <Loader ref="loaderRef"/>
    <!-- the message loader -->
    <Message :title="title" :message="processMessage" ref="messageRef"/>
      <!-- add expense report details modal -->
      <TransitionRoot appear :show="isAddBudgetOpen" @close="closeAddBudgetModal()" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0 bg-black opacity-80" />
              </TransitionChild>
  
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
  
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="inline-block border w-full max-w-5xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
                >
                  <DialogTitle
                    as="h4"
                    class="text-size font-medium leading-6 text-gray-600"
                  >
                  Add Expense Report Details
                  </DialogTitle>
                  <div class="grid grid-flow-row grid-cols-2 grid-rows-2 gap-4 py-4">
                     <!-- Date -->
                     <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Date <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="date"
                          required
                          type="date"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Description -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Description <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="description"
                          placeholder="Description"
                          required
                          class="bg-gray-50 text-size-md shadow-sm text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Hotel -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Hotel <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="hotel"
                          required
                          placeholder="Hotel"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- MI&E -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">MI&E <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="mie"
                          required
                          placeholder="MI&E"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Other -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Other <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="other"
                          required
                          placeholder="Other"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Notes -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Notes <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="notes"
                          required
                          placeholder="Notes"
                          type="text"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                  </div>
                  <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                    <button
                      @click="closeAddBudgetModal()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Cancel
                    </button>
                    <button
                      @click="addexpenseReportData()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Save
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
      <!-- edit the expense report details modal -->
      <TransitionRoot appear :show="isEditBudgetOpen" @close="closeEditBudgetModal()" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0 bg-black opacity-80" />
              </TransitionChild>
  
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
  
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="inline-block border w-full max-w-5xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
                >
                  <DialogTitle
                    as="h4"
                    class="text-size font-medium leading-6 text-gray-600"
                  >
                  Edit Expense Report Details
                  </DialogTitle>
                  <div class="grid grid-flow-row grid-cols-2 grid-rows-2 gap-4 py-4">
                     <!-- Date -->
                     <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Date <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="date"
                          required
                          type="date"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Description -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Description <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="description"
                          placeholder="Description"
                          required
                          class="bg-gray-50 text-size-md shadow-sm text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Hotel -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Hotel <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="hotel"
                          required
                          placeholder="Hotel"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- MI&E -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">MI&E <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="mie"
                          required
                          placeholder="MI&E"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Other -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Other <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="other"
                          required
                          placeholder="Other"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Notes -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Notes <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="notes"
                          required
                          placeholder="Notes"
                          type="text"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                  </div>
                  <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                    <button
                      @click="closeAddBudgetModal()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Cancel
                    </button>
                    <button
                      @click="editexpenseReportData()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Save
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
  </div>
</template>

<script>
import Navbar from '@/components/EmployeeNavbar.vue'
import Sidebar from '@/components/EmployeeSideBar.vue'
import { mapState } from 'vuex'
import { ajax } from 'rxjs/ajax'
import Message from './modals/Message.vue';
import Loader from './modals/Loader.vue';
  import { ref } from 'vue'
// import CustomDropDown from './CustomDropDown.vue';
 import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue'

export default {
  computed: {
    ...mapState([
      'employee',
      'liquidationRequest',
      'expenseReport',
      'liquidationCurrencyAndFunding'
    ])
  },
  components: {
    Navbar,
    Sidebar,
    Loader,
    Message,
    // CustomDropDown
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay
  },
  setup () {
    const isAddBudgetOpen = ref(false)
    const isEditBudgetOpen = ref(false)
    return {
      isAddBudgetOpen,
      isEditBudgetOpen,
      closeAddBudgetModal () {
        isAddBudgetOpen.value = false
      },
      openAddBudgetModal () {
        isAddBudgetOpen.value = true
      },
      closeEditBudgetModal () {
        isEditBudgetOpen.value = false
      },
      openEditBudgetModal () {
        isEditBudgetOpen.value = true
      }
    }
  },
  name: 'admin-home',
  props: {
  },
  data: () => {
    return {
      date: '',
      description: '',
      hotel: 0,
      mie: 0,
      other: 0,
      notes: 'N/A',
      currency: '',
      title: 'Error Message',
      processMessage: '',
      selectedRequest: {},
      expenseReportData: [],
      mieAdvance: '',
      lodgingAdvance: '',
      otherAdvance: '',
      showExpenseReport: false,
      showLiquidation: false,
      selectedEditIndex: -1,
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    }
  },
  mounted () {
    // removing the submenu items selection
    if (this.employee !== null) {
      this.$store.commit('mutateActiveListItemIndex', 200)
      this.$store.commit('mutateSelectedSubMenuItem', 200)
      // load the vuex data
      this.loadLiquidation()
    }
  },
  methods: {
    // edit budget data
    editexpenseReportData () {
        // check if the details are empty
        // if (this.date !== '' && this.description !== '' && this.hotel !== '' && this.mie !== '' && this.other !== '') {
        if (this.description !== '' && this.hotel !== '' && this.mie !== '' && this.other !== '') {
          // hide the budget
          this.showExpenseReport = false

          // remove the last grand total row if its available
          this.deleteGrandTotalRow()

          // get the budget sources && currency
          const budgetSources = this.liquidationCurrencyAndFunding.budgetSources.map((data) => `${data.name} ${data.percentage}%`).join(', ')
          const currency = this.liquidationCurrencyAndFunding.currency.allData.acronym

          // adding the data
          const rowData = {
            date: this.date,
            description: this.description,
            project: budgetSources,
            currency: currency,
            hotel: this.hotel,
            mie: this.mie,
            other: this.other,
            comments: this.notes
          }
          
          // add it in the budget
          this.expenseReportData[this.selectedEditIndex] = rowData

          // calculate the grand total
          this.calculateGrandTotal()
          
          // show the budget
          this.showExpenseReport = true
          
          //  close the add a budget modal
          this.closeEditBudgetModal()
        }
      },
    // load data for the selected index
    loadEditBudgetModal (index) {
      // assign the selected index
      this.selectedEditIndex = index
      // load the data
      const data = this.expenseReportData[index]
      this.date = data.date
      this.description = data.description
      this.hotel = data.hotel
      this.mie = data.mie
      this.other = data.other
      this.notes = data.comments
      // open the edit modal
      this.openEditBudgetModal()
    },
    // add expense data
    addexpenseReportData () {
        // check if the details are empty
        // if (this.date !== '' && this.description !== '' && this.hotel !== '' && this.mie !== '' && this.other !== '') {
        if (this.description !== '' && this.hotel !== '' && this.mie !== '' && this.other !== '') {
          // hide the expense report
          this.showExpenseReport = false
          // delete the grand total row
          this.deleteGrandTotalRow()

          // get the budget sources && currency
          const budgetSources = this.liquidationCurrencyAndFunding.budgetSources.map((data) => `${data.name} ${data.percentage}%`).join(', ')
          const currency = this.liquidationCurrencyAndFunding.currency.allData.acronym

          // adding the data
          const rowData = {
            date: this.date,
            description: this.description,
            project: budgetSources,
            currency: currency,
            hotel: this.hotel,
            mie: this.mie,
            other: this.other,
            comments: this.notes
          }
          
          // add it in the expense report
          this.expenseReportData.push(rowData)
          
          // calculate the grand total
          this.calculateGrandTotal()

          // show the expense report
          this.showExpenseReport = true
          
          //  close the add a expense report modal
          this.closeAddBudgetModal()
        }
      },
    // calculate the hotel, mie, and other totals
    calculateGrandTotal () {
      let size = this.expenseReportData.length
      let hotelTotal = 0
      let mieTotal = 0
      let otherTotal = 0

      for (let i = 0; i < size; i++) {
        const data = this.expenseReportData[i]
        let lodgingRowTotal = data.hotel
        let mieRowTotal = data.mie
        let otherRowTotal = data.other

        console.log(lodgingRowTotal, '-', isNaN(lodgingRowTotal))

        if (isNaN(lodgingRowTotal) || lodgingRowTotal === '') {
          lodgingRowTotal = 0
        }

        if (isNaN(mieRowTotal) || mieRowTotal === '') {
          mieRowTotal = 0
        }

        if (isNaN(otherRowTotal) || otherRowTotal === '') {
          otherRowTotal = 0
        }

        // sum up the totals
        hotelTotal += parseFloat(lodgingRowTotal)
        mieTotal += parseFloat(mieRowTotal)
        otherTotal += parseFloat(otherRowTotal)
      }

      // add the expense report data
      const totalsData = {
                date: '',
                description: '',
                project: '',
                currency: 'Total',
                hotel: hotelTotal,
                mie: mieTotal,
                other: otherTotal,
                comments: ''
              }
      this.expenseReportData.push(totalsData)

      this.$store.commit('mutateExpenseReport', this.expenseReportData)
    },
    deleteGrandTotalRow () {
      // remove the grand total row last row
      const size = (this.expenseReportData.length - 1)
      this.expenseReportData.splice(size, 1)
    },
    // delete's an expense report's item
    deleteItem (i) {
      this.showExpenseReport = false
      // remove the grand total row last row
      this.deleteGrandTotalRow()
      
      // delete the item
      this.expenseReportData.splice(i, 1)

      // insert the new grand total row
      this.calculateGrandTotal()
      this.showExpenseReport = true
    },
    // resets the expense report
    resetExpenseReport () {
      this.showExpenseReport = false
      this.expenseReportData = []
      this.getRequest()
      this.showExpenseReport = true
    },
    // format the table data
    formatData (data) {
      var formattedData
      // check if it is a number
      if (data !== null && data !== undefined && !isNaN(data) && String(data).length > 2) {
        // format the number to a currency format
        var currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'MWK' })
        formattedData = currencyFormat.format(String(data)).substring(4)
      } else if (data !== null && data !== undefined && data.toString().includes('-') && !isNaN(Date.parse(data))) {
        // check if the data is a date
        // formating the dates supplied
        var textDate = new Date(data)
        var formattedTextDate = textDate.getDate() + ' ' + this.months[textDate.getMonth()] + ' ' + textDate.getFullYear()
        formattedData = formattedTextDate
      } else {
        formattedData = data
      }
      return formattedData
    },
    // load the vuex data
    loadLiquidation () {
      // load the expense report from vuex if its there or from the back-end
      if (this.expenseReport !== null) {
        this.showExpenseReport = false
        this.expenseReportData = this.expenseReport
        this.showExpenseReport = true
      } else {
        this.getRequest()
      }
      this.showLiquidation = true
    },
    // getting the pending activity approvals
    getRequest () {
      const id = this.liquidationRequest.id
      const observable = ajax({
        url: process.env.VUE_APP_BASE_URL + `/request/findById?id=${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      })
      const observer = {
        next: (response) => {
          const data = response.response
          const size = data.length
          // check the size
          if (size > 0) {
            // let expenseData = []
            const advance = data[0].advance
            const projectName = advance.advance.budgetSource.map((data) => `${data.name} ${data.percentage}%`).join(', ')
            const mie = advance.mie
            const other = advance.other
            const lodging = advance.lodging
            const currency = advance.advance.currency.allData.acronym

            // the budget totals 
            let hotelTotal = 0
            let mieTotal = 0
            let otherTotal = 0
            // adding the mie expenses
            if (mie !== null && mie!==undefined && Array.isArray(mie)) {
              const mieSize = mie.length

              for (let i = 0; i < (mieSize - 1); i++) {
                const mieData = mie[i]
                const date = mieData.startDate
                const description = mieData.description
                const project = projectName
                const hotel = 0
                let mieCost = mieData.total
                const other = 0
                const comments = ''

                if (i === 0 || i === (mieSize - 2)) {
                  mieCost = mieCost * 0.75
                }

                // add the expense report data
                const mieExpenseData = {
                  date: date,
                  description: description,
                  project: project,
                  currency: currency,
                  hotel: hotel,
                  mie: mieCost,
                  other: other,
                  comments: comments
                }

                // add the data in the expense report
                this.expenseReportData.push(mieExpenseData)
                mieTotal += mieCost
              }
              // mieTotal = mie[(mieSize - 1)].total

            }

            // add the other details
            if (other !== null && other !== undefined && Array.isArray(other) && other.length > 0) {
              const otherSize = other.length

              for (let i = 0; i < (otherSize - 1); i++) {
                const otherData = other[i]
                const date = ''
                const description = otherData.item
                const project = projectName
                const hotel = 0
                const mieCost = 0
                const otherCost = otherData.total
                const comments = ''

                // add the expense report data
                const otherExpenseData = {
                  date: date,
                  description: description,
                  project: project,
                  currency: currency,
                  hotel: hotel,
                  mie: mieCost,
                  other: otherCost,
                  comments: comments
                }

                // add the data in the expense report
                this.expenseReportData.push(otherExpenseData)
              }

              otherTotal = other[(otherSize - 1)].total

            }

            // add the lodging details
            if (lodging !== null && lodging !== undefined && Array.isArray(lodging) && lodging.length > 0) {
              const lodgingSize = lodging.length

              for (let i = 0; i < (lodgingSize - 1); i++) {
                const lodgingData = lodging[i]
                const date = ''
                const description = lodgingData.description
                const project = projectName
                const hotel = lodgingData.total
                const mieCost = ''
                const otherCost = ''
                const comments = ''

                // add the expense report data
                const lodgingExpenseData = {
                  date: date,
                  description: description,
                  project: project,
                  currency: currency,
                  hotel: hotel,
                  mie: mieCost,
                  other: otherCost,
                  comments: comments
                }

                // add the data in the expense report
                this.expenseReportData.push(lodgingExpenseData)
              }

              hotelTotal = lodging[(lodgingSize - 1)].total

            }

            // add the expense report data
            const totalsData = {
                  date: '',
                  description: '',
                  project: '',
                  currency: 'Total',
                  hotel: hotelTotal,
                  mie: mieTotal,
                  other: otherTotal,
                  comments: ''
                }
            this.expenseReportData.push(totalsData)

            // show the expense report
            this.showExpenseReport = true
            // this.expenseReportData = expenseData
            this.$store.commit('mutateExpenseReport', this.expenseReportData)
          }
        },
        error: (err) => {
          const status = err.status
          if (status === 401) {
            this.$router.push('/')
          }
        },
        complete: () => {}
      }
      observable.subscribe(observer)
    },
    // calculates the date difference in days for the schedule days
    dateDiffInDays () {
        // the date which the activity starts
        const days = []
        const scheduleSize = this.scheduleData.length

        for (let i = 0; i < scheduleSize; i++) {
          const startDate = this.scheduleData[i].startDate
          days.push(startDate)
        }

        // sort the days and get the minimum day
        days.sort()
        const miniDate = days[0]
        const dateFrom = new Date(miniDate)


        // today's date
        const dateTo = new Date()

        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // remove the time and time-zone details.
        const utc1 = Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate());
        const utc2 = Date.UTC(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    },
    // move back
    back () {
      this.$router.push('/create-liquidation-1')
    },
    // move to the next page
    next () {
      // validate the data
      if (this.expenseReportData !== null && Array.isArray(this.expenseReportData) && this.expenseReportData.length > 0) {
        this.$store.commit('mutateExpenseReport', this.expenseReportData)
        this.$router.push('/create-liquidation-3')
      } else {
        // fill all the fields
        // close the loader modal
        this.$refs.loaderRef.closeModal()
        // showing the dialog for the error
        this.processMessage = 'Please fill all the fields to continue.'
        // open the error modal
        this.$refs.messageRef.openModal()
      }
    },
    // expands or collapses the main body
    expand (value) {
      this.expandBody = value
    },
    // submits the signature
    submit (filename) {
      const observable = ajax({
        url: process.env.VUE_APP_BASE_URL + '/signature/insertFile',
        method: 'POST',
        body: {
          employee_id: this.employee.id,
          filename: filename
        },
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      })

      const observer = {
        next: () => {
          // move to the profile page
          this.$router.push('/employee-profile')
        },
        error: () => {
          // close the loader modal
          this.$refs.loaderRef.closeModal()
          // showing the dialog for the error
          this.processMessage = 'Failed to submit the signature, please try again.'
          // open the error modal
          this.$refs.messageRef.openModal()
        },
        complete: () => {
          this.$refs.loaderRef.closeModal()
        }
      }

      observable.subscribe(observer)
    }
  }
}
</script>

<style>

#chart-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 40vh;
}

</style>
