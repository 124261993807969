<template>
    <WithdrawnRequests></WithdrawnRequests>
</template>
<script>
    import WithdrawnRequests from '@/components/EmployeeWithdrawnRequests.vue'
    export default {
        name: 'approved-liquidations',
        components: {
            WithdrawnRequests
        }
    }
</script>