<template>
    <AdminView75Liquidation></AdminView75Liquidation>
</template>
<script>
    import AdminView75Liquidation from '@/components/AdminView75Liquidation.vue'
    export default {
        name: 'Admin-sign',
        components: {
            AdminView75Liquidation
        }
    }
</script>