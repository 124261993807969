<template>
    <AdminTrack25Liquidation></AdminTrack25Liquidation>
</template>
<script>
    import AdminTrack25Liquidation from '@/components/AdminTrack25Liquidation.vue'
    export default {
        name: 'Admin-track-liquidation',
        components: {
            AdminTrack25Liquidation
        }
    }
</script>