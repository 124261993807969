<template>
  <div class="w-full h-screen bg-white overflow-x-hidden">
    <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
    <div>
      <Sidebar class="sidebar"/>
      <div :class="[!expandBody ? 'main px-5 space-y-2 margin mb-16' : 'main px-5 space-y-2 mb-16']">
        <!-- the top route and username grid -->
        <div class="justify-between flex pt-5 text-size-md font-medium text-gray-500 w-full">
          <span class=" text-emerald-500" >> Home > View Request</span>
          <span v-if="employee !== null">{{ employee.firstname }} {{ employee.surname }} </span>
        </div>
        <!-- the requests charts -->
        <div class="grid gap-6 grid-rows-1 grid-cols-1">
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg mt-4 bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-6 mb-4 items-center space-x-1">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,24H3a3,3,0,0,1-3-3V0H2V21a1,1,0,0,0,1,1H24Z"/><rect x="14" y="11" width="2" height="9"/><rect x="6" y="11" width="2" height="9"/><rect x="18" y="6" width="2" height="14"/><rect x="10" y="6" width="2" height="14"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m12,12c3.309,0,6-2.691,6-6S15.309,0,12,0s-6,2.691-6,6,2.691,6,6,6Zm0-10c2.206,0,4,1.794,4,4s-1.794,4-4,4-4-1.794-4-4,1.794-4,4-4Zm9,17v5h-2v-5c0-1.654-1.346-3-3-3h-2l-1.333,2,1.333,6h-4l1.333-6-1.333-2h-2c-1.654,0-3,1.346-3,3v5h-2v-5c0-2.757,2.243-5,5-5h8c2.757,0,5,2.243,5,5Z"/>
              </svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M18.4,7.379a1.128,1.128,0,0,1-.769-.754h0a8,8,0,1,0-15.1,5.237A1.046,1.046,0,0,1,2.223,13.1,5.5,5.5,0,0,0,.057,18.3,5.622,5.622,0,0,0,5.683,23H11a1,1,0,0,0,1-1h0a1,1,0,0,0-1-1H5.683a3.614,3.614,0,0,1-3.646-2.981,3.456,3.456,0,0,1,1.376-3.313A3.021,3.021,0,0,0,4.4,11.141a6.113,6.113,0,0,1-.073-4.126A5.956,5.956,0,0,1,9.215,3.05,6.109,6.109,0,0,1,9.987,3a5.984,5.984,0,0,1,5.756,4.28,2.977,2.977,0,0,0,2.01,1.99,5.934,5.934,0,0,1,.778,11.09.976.976,0,0,0-.531.888h0a.988.988,0,0,0,1.388.915c4.134-1.987,6.38-7.214,2.88-12.264A6.935,6.935,0,0,0,18.4,7.379Z"/><path d="M18.707,16.707a1,1,0,0,0,0-1.414l-1.586-1.586a3,3,0,0,0-4.242,0l-1.586,1.586a1,1,0,0,0,1.414,1.414L14,15.414V23a1,1,0,0,0,2,0V15.414l1.293,1.293a1,1,0,0,0,1.414,0Z"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m17,0H7C4.243,0,2,2.243,2,5v14c0,2.757,2.243,5,5,5h10c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5ZM7,2h10c1.654,0,3,1.346,3,3H4c0-1.654,1.346-3,3-3Zm10,20H7c-1.654,0-3-1.346-3-3V7h16v12c0,1.654-1.346,3-3,3Zm0-11c0,.553-.448,1-1,1h-8c-.552,0-1-.447-1-1s.448-1,1-1h8c.552,0,1,.447,1,1Zm-4,4c0,.553-.448,1-1,1h-4c-.552,0-1-.447-1-1s.448-1,1-1h4c.552,0,1,.447,1,1Z"/></svg> -->
              <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m13,5v2H5v-2h8Zm-7,6h6v-2h-6v2Zm17,4.764v5.236c0,1.654-1.346,3-3,3H1V0h17c1.654,0,3,1.346,3,3v8.764l2,4ZM3,22h12V2H3v20Zm18-5.764l-2-4V3c0-.551-.448-1-1-1h-1v20h3c.552,0,1-.449,1-1v-4.764Z"/>
              </svg>
              <p class="text-left text-size-md font-medium text-gray-600">View Request</p>
            </div>
            <!--  chart -->
            <div class="ml-8 pb-8 mt-4">
              <div
                class="ring-1 py-2 text-left cursor-pointer ring-gray-200 shadow-md mr-8 pl-1 chart-height"
              >
                <div v-if="requestData !== null" class="px-4 py-3 space-y-2.5">
                  <div class="mx-4 mb-5 px-4 py-2 grid grid-flow-row grid-cols-1 gap-6">
                    <!-- the selector tab -->
                    <div class="flex mb-2 justify-center">
                      <div class="my-auto">
                        <button @click="switchView(1)" :class="[ currentView == 1 ? 'shadow inline-block box-shadow text-size-sm px-20 py-2 bg-emerald-700  text-white font-medium leading-tight transform focus:bg-emerald-700 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out' : 'shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out']">View Request Step 1</button>
                        <button @click="switchView(2)" :class="[ currentView == 2 ? 'shadow inline-block box-shadow text-size-sm px-20 py-2 bg-emerald-700  text-white font-medium leading-tight transform focus:bg-emerald-700 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out' : 'shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out']">View Hotel Need</button>
                        <button @click="switchView(3)" :class="[ currentView == 3 ? 'shadow inline-block box-shadow text-size-sm px-20 py-2 bg-emerald-700  text-white font-medium leading-tight transform focus:bg-emerald-700 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out' : 'shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out']">View Advances</button>
                        <button @click="switchView(4)" :class="[ currentView == 4 ? 'shadow inline-block box-shadow text-size-sm px-20 py-2 bg-emerald-700  text-white font-medium leading-tight transform focus:bg-emerald-700 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out' : 'shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out']">View Terms of Reference</button>
                        <button v-if="requestAttachments !== null && Array.isArray(requestAttachments) && requestAttachments.length > 0" @click="switchView(5)" :class="[ currentView == 5 ? 'shadow inline-block box-shadow text-size-sm px-20 py-2 bg-emerald-700  text-white font-medium leading-tight transform focus:bg-emerald-700 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out' : 'shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out']">View Attachments</button>
                      </div>
                    </div>
                    <!-- step 1 -->
                    <table v-if="advance !== null && currentView === 1" id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                        <thead>
                          <tr class="bg-emerald-600 text-size-xs pointer-events-none" style="">
                            <td class="py-2.5 px-2 text-left text-white font-bold">Item</td>
                            <td class="py-2.5 px-2 text-left text-white font-bold">Description</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class=" text-gray-600 text-size-sm" style="">
                            <td class="py-2.5 px-2 border text-left font-medium">Date Submitted</td>
                            <td class="py-2.5 px-2 border text-left">{{ requestData[0].date }}</td>
                          </tr>
                          <tr class=" text-gray-600 text-size-sm" style="">
                            <td class="py-2.5 px-2 border text-left font-medium">Source of Funds</td>
                            <td class="py-2.5 px-2 border text-left">{{ advance.budgetSource.map((data) => `${data.name} ${data.percentage}%`).join(', ')}}</td>
                          </tr>
                          <tr class=" text-gray-600 text-size-sm" style="">
                            <td class="py-2.5 px-2 border text-left font-medium">Departure Date</td>
                            <td class="py-2.5 px-2 border text-left">{{ formatData(advance.departureDate) }}</td>
                          </tr>
                          <tr class=" text-gray-600 text-size-sm" style="">
                            <td class="py-2.5 px-2 border text-left font-medium">Return Date</td>
                            <td class="py-2.5 px-2 border text-left">{{ formatData(advance.returnDate) }}</td>
                          </tr>
                          <tr class=" text-gray-600 text-size-sm" style="">
                            <td class="py-2.5 px-2 border text-left font-medium">Duration</td>
                            <td class="py-2.5 px-2 border text-left">{{ formatData(dateDiff(advance.departureDate, advance.returnDate)) }} Day(s)</td>
                          </tr>
                          <tr class=" text-gray-600 text-size-sm" style="">
                            <td class="py-2.5 px-2 border text-left font-medium">Departure Time</td>
                            <td class="py-2.5 px-2 border text-left">{{ formatData(advance.departureTime) }}</td>
                          </tr>
                          <tr class=" text-gray-600 text-size-sm" style="">
                            <td class="py-2.5 px-2 border text-left font-medium">Return Time</td>
                            <td class="py-2.5 px-2 border text-left">{{ formatData(advance.returnTime) }}</td>
                          </tr>
                          <tr class=" text-gray-600 text-size-sm" style="">
                            <td class="py-2.5 px-2 border text-left font-medium">Transportation Mode</td>
                            <td class="py-2.5 px-2 border text-left">{{ formatData(advance.transportation.map((data) => data.name.replace(/,/g, '')).join(', ')) }}</td>
                          </tr>
                          <tr class=" text-gray-600 text-size-sm" style="">
                            <td class="py-2.5 px-2 border text-left font-medium">Name of Companions</td>
                            <td class="py-2.5 px-2 border text-left">{{ advance.noOfPeople }}</td>
                          </tr>
                          <tr class=" text-gray-600 text-size-sm" style="">
                            <td class="py-2.5 px-2 border text-left font-medium">If Air please precise the number of people accompanying</td>
                            <td class="py-2.5 px-2 border text-left">{{ advance.companions }}</td>
                          </tr>
                          <tr class=" text-gray-600 text-size-sm" style="">
                            <td class="py-2.5 px-2 border text-left font-medium">Destinations</td>
                            <td class="py-2.5 px-2 border text-left">{{ formatData(advance.destinations.map((data) => data.name.replace(/,/g, '')).join(', ')) }}</td>
                          </tr>
                          <tr class=" text-gray-600 text-size-sm" style="">
                            <td class="py-2.5 px-2 border text-left font-medium">Reason of Travel</td>
                            <td class="py-2.5 px-2 border text-left">{{ formatData(advance.reasonOfTravel) }}</td>
                          </tr>
                        </tbody>
                    </table>
                    <!-- need for a hotel -->
                    <div v-if="hotelNeed !== null &&  hotel !== null && currentView === 2" class="space-y-8">
                      <table  id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                          <thead>
                            <tr class="bg-emerald-600 text-size-xs pointer-events-none" style="">
                              <td class="py-2.5 px-2 text-left text-white font-bold">Item</td>
                              <td class="py-2.5 px-2 text-left text-white font-bold">Description</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class=" text-gray-600 text-size-sm" style="">
                              <td class="py-2.5 px-2 border text-left font-medium">Hotel Need at Destination</td>
                              <td class="py-2.5 px-2 border text-left">{{ hotelNeed.name }}</td>
                            </tr>
                          </tbody>
                      </table>
                      <!-- the hotel need details -->
                      <table v-if="hotelNeed.name === 'Yes'" id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                          <thead>
                            <tr class="bg-emerald-600 pointer-events-none text-size-xs" style="">
                              <td class="py-3 px-2 text-left text-white font-bold">Arrival Date</td>
                              <td class="py-3 px-2 border-l text-left text-white font-bold">Departure Date</td>
                              <td class="py-3 px-2 border-l text-left text-white font-bold">Place</td>
                              <td class="py-3 px-2 border-l text-left text-white font-bold">No of Nights</td>
                              <td class="py-3 px-2 border-l text-left text-white font-bold">Reservation No</td>
                              <td class="py-3 px-2 border-l text-left text-white font-bold">Value</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(data, i) in hotel" :key="i" class=" text-gray-600 text-size-sm" style="">
                              <td class="py-3 px-2 border text-left">{{ formatData(data.arrivalDate) }}</td>
                              <td class="py-3 px-2 border text-left">{{ formatData(data.departureDate) }}</td>
                              <td class="py-3 px-2 border text-left">{{ data.place.name }}</td>
                              <td class="py-3 px-2 border text-left">{{ data.nights }}</td>
                              <td v-if="data.reservationNumber !== 'Total Cost'" class="py-3 px-2 border text-left">{{ data.reservationNumber }}</td>
                              <td v-else class="py-3 px-2 border text-left font-medium">{{ data.reservationNumber }}</td>
                              <td v-if="data.reservationNumber !== 'Total Cost'" class="py-3 px-2 border text-left">{{ formatData(data.value) }}</td>
                              <td v-else class="py-3 px-2 border text-left font-medium">{{ formatData(data.value) }}</td>
                            </tr>
                          </tbody>
                      </table>
                    </div>
                    <!-- the advances -->
                    <div v-if="currentView === 3" class="space-y-8">
                      <div>
                        <span class=" text-size-md font-medium text-gray-500 py-4">All the Advances Total {{ advance.currency.allData.acronym }} {{ formatData(calculateAdvanceTotals()) }}</span>
                        <table v-if="mieAdvance !== null" id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                            <thead>
                              <tr class="bg-emerald-600 pointer-events-none text-size-xs" style="">
                                <td class="py-3 px-2 text-left text-white font-bold">Location</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Start Date</td>
                                <!-- <td class="py-3 px-2 border-l text-left text-white font-bold">End Date</td> -->
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Breakfast</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Lunch</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Dinner</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Incidental</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold"># of Days</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">MIE Total (100%)</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Advance (75%)</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(data, i) in mieAdvance" :key="i" class=" text-gray-600 text-size-sm" style="">
                                <td class="py-3 px-2 border text-left">{{ data.location.name }}</td>
                                <td class="py-3 px-2 border text-left">{{ formatData(data.startDate) }}</td>
                                <!-- <td class="py-3 px-2 border text-left">{{ formatData(data.endDate) }}</td> -->
                                <td class="py-3 px-2 border text-left">{{ data.breakfast.name }}</td>
                                <td class="py-3 px-2 border text-left">{{ data.lunch.name }}</td>
                                <td class="py-3 px-2 border text-left">{{ data.dinner.name }}</td>
                                <td class="py-3 px-2 border text-left">{{ data.incidental.name }}</td>
                                <td v-if="data.days !== 'Total Cost'" class="py-3 px-2 border text-left">{{ data.days }}</td>
                                <td v-else class="py-3 px-2 border text-left font-medium">{{ data.days }}</td>
                                <td v-if="data.days !== 'Total Cost'" class="py-3 px-2 border text-left">{{ formatData(data.total) }}</td>
                                <td v-else class="py-3 px-2 border text-left font-medium">{{ formatData(data.total) }}</td>
                                <td v-if="data.days !== 'Total Cost'" class="py-3 px-2 border text-left">{{ formatData(data.advance) }}</td>
                                <td v-else class="py-3 px-2 border text-left font-medium">{{ formatData(data.advance) }}</td>
                              </tr>
                            </tbody>
                        </table>

                        <table v-if="mieIntlAdvance !== null && mieIntlAdvance !== undefined" id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                            <thead>
                              <tr class="bg-emerald-600 pointer-events-none text-size-xs" style="">
                                <td class="py-3 px-2 text-left text-white font-bold">Location</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Date</td>
                                <!-- <td class="py-3 px-2 border-l text-left text-white font-bold">End Date</td> -->
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Breakfast</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Lunch</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Dinner</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Incidental</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold"># of Days</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">MIE Total (100%)</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Advance (75%)</td>
                                <!-- <td class="py-3 px-2 border-l text-left text-white font-bold">Travel Days Advance</td> -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(data, i) in mieIntlAdvance" :key="i" class=" text-gray-600 text-size-sm" style="">
                                <td class="py-3 px-2 border text-left">{{ data.location.name }}</td>
                                <td class="py-3 px-2 border text-left">{{ formatData(data.startDate) }}</td>
                                <!-- <td class="py-3 px-2 border text-left">{{ formatData(data.endDate) }}</td> -->
                                <td class="py-3 px-2 border text-left">{{ data.breakfast }}</td>
                                <td class="py-3 px-2 border text-left">{{ data.lunch }}</td>
                                <td class="py-3 px-2 border text-left">{{ data.dinner }}</td>
                                <td class="py-3 px-2 border text-left">{{ data.incidental }}</td>
                                <td v-if="data.days !== 'Total Cost'" class="py-3 px-2 border text-left">{{ data.days }}</td>
                                <td v-else class="py-3 px-2 border text-left font-medium">{{ data.days }}</td>
                                <td v-if="data.days !== 'Total Cost'" class="py-3 px-2 border text-left">{{ formatData(data.total) }}</td>
                                <td v-else class="py-3 px-2 border text-left font-medium">{{ formatData(data.total) }}</td>
                                <td v-if="data.days !== 'Total Cost'" class="py-3 px-2 border text-left">{{ formatData(data.advance) }}</td>
                                <td v-else class="py-3 px-2 border text-left font-medium">{{ formatData(data.advance) }}</td>

                                <!-- the first & last days math-->
                                <!-- <td v-if="data.days !== 'Total Cost' && (i == 0 || (mieAdvance.length - 2) === i)" class="py-3 px-2 border text-left">{{ formatData(data.advance * 0.75) }}</td>
                                <td v-else-if="data.days !== 'Total Cost'" class="py-3 px-2 border text-left">{{ formatData(data.advance) }}</td>
                                <td v-else class="py-3 px-2 border text-left font-medium">{{ formatData((data.advance - (mieAdvance[0].advance * 0.25) -  (mieAdvance[mieAdvance.length - 2].advance * 0.25) )) }}</td> -->
                              </tr>
                            </tbody>
                        </table>
                      </div>
                      <table v-if="lodgingAdvance !== null" id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                          <thead>
                            <tr class="bg-emerald-600 pointer-events-none text-size-xs" style="">
                              <td class="py-3 px-2 text-left text-white font-bold">Description</td>
                              <td class="py-3 px-2 text-left text-white font-bold">Rate</td>
                              <td class="py-3 px-2 border-l text-left text-white font-bold"># of Days</td>
                              <td class="py-3 px-2 border-l text-left text-white font-bold">Total (100%)</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(data, i) in lodgingAdvance" :key="i" class=" text-gray-600 text-size-sm" style="">
                              <td class="py-3 px-2 border text-left">{{ data.description }}</td>
                              <td class="py-3 px-2 border text-left">{{ formatData(data.rate) }}</td>
                              <td v-if="data.noDays !== 'Total Cost'" class="py-3 px-2 border text-left">{{ data.noDays }}</td>
                              <td v-else class="py-3 px-2 border text-left font-medium">{{ data.noDays }}</td>
                              <td v-if="data.noDays !== 'Total Cost'" class="py-3 px-2 border text-left">{{ formatData(data.total) }}</td>
                              <td v-else class="py-3 px-2 border text-left font-medium">{{ formatData(data.total) }}</td>
                            </tr>
                          </tbody>
                      </table>
                      <table v-if="otherAdvance !== null" id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                          <thead>
                            <tr class="bg-emerald-600 pointer-events-none text-size-xs" style="">
                              <td class="py-3 px-2 text-left text-white font-bold">Item</td>
                              <td class="py-3 px-2 text-left text-white font-bold">Quantity</td>
                              <td class="py-3 px-2 border-l text-left text-white font-bold">Price</td>
                              <td class="py-3 px-2 border-l text-left text-white font-bold">Total</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(data, i) in otherAdvance" :key="i" class=" text-gray-600 text-size-sm" style="">
                              <td class="py-3 px-2 border text-left">{{ data.item }}</td>
                              <td class="py-3 px-2 border text-left">{{ formatData(data.quantity) }}</td>
                              <td v-if="data.price !== 'Total Cost'" class="py-3 px-2 border text-left">{{ data.price }}</td>
                              <td v-else class="py-3 px-2 border text-left font-medium">{{ data.price }}</td>
                              <td v-if="data.price !== 'Total Cost'" class="py-3 px-2 border text-left">{{ formatData(data.total) }}</td>
                              <td v-else class="py-3 px-2 border text-left font-medium">{{ formatData(data.total) }}</td>
                            </tr>
                          </tbody>
                      </table>
                    </div>
                    <!-- tor -->
                    <table v-if="tor !== null && currentView === 4" id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                        <thead>
                          <tr class="bg-emerald-600 text-size-xs pointer-events-none" style="">
                            <td class="py-2.5 px-2 text-left text-white font-bold">Item</td>
                            <td class="py-2.5 px-2 text-left text-white font-bold">Description</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class=" text-gray-600 text-size-sm" style="">
                            <td class="py-2.5 px-2 border text-left font-medium">Travellers names</td>
                            <td class="py-2.5 px-2 border text-left">{{ tor.travellers.map((data) => data.name).join(', ')}}</td>
                          </tr>
                          <tr class=" text-gray-600 text-size-sm" style="">
                            <td class="py-2.5 px-2 border text-left font-medium">Team Leader</td>
                            <td class="py-2.5 px-2 border text-left">{{ formatData(tor.teamLeader.name) }}</td>
                          </tr>
                          <tr class=" text-gray-600 text-size-sm" style="">
                            <td class="py-2.5 px-2 border text-left font-medium">Dates</td>
                            <td class="py-2.5 px-2 border text-left">{{ formatData(advance.departureDate) }} - {{ formatData(advance.returnDate) }}</td>
                          </tr>
                          <tr class=" text-gray-600 text-size-sm" style="">
                            <td class="py-2.5 px-2 border text-left font-medium">No of Days</td>
                            <td class="py-2.5 px-2 border text-left">{{ formatData(dateDiff(advance.departureDate, advance.returnDate)) }} Day(s)</td>
                          </tr>
                          <tr class=" text-gray-600 text-size-sm" style="">
                            <td class="py-2.5 px-2 border text-left font-medium">Requested By</td>
                            <td class="py-2.5 px-2 border text-left">{{ tor.visitRequestor.name }}</td>
                          </tr>
                          <tr class=" text-gray-600 text-size-sm" style="">
                            <td class="py-2.5 px-2 border text-left font-medium">Introduction</td>
                            <td class="py-2.5 px-2 border text-left" v-html="tor.justification"></td>
                          </tr>
                          <tr class=" text-gray-600 text-size-sm" style="">
                            <td class="py-2.5 px-2 border text-left font-medium">Expected products from this visit</td>
                            <td class="py-2.5 px-2 border text-left" v-html="tor.output"></td>
                          </tr>
                          <tr class=" text-gray-600 text-size-sm" style="">
                            <td class="py-2.5 px-2 border text-left font-medium">Expected results</td>
                            <td class="py-2.5 px-2 border text-left" v-html="tor.results"></td>
                          </tr>
                          <tr class=" text-gray-600 text-size-sm" style="">
                            <td class="py-2.5 px-2 border text-left font-medium">Organization of visit</td>
                            <td class="py-2.5 px-2 border text-left" v-html="tor.preparatoryActivity"></td>
                          </tr>
                          <tr class=" text-gray-600 text-size-sm" style="">
                            <td class="py-2.5 px-2 border text-left font-medium">Program Visit</td>
                            <td class="py-2.5 px-2 border text-left" v-html="tor.activities"></td>
                          </tr>
                        </tbody>
                    </table>
                    <!-- attachments -->
                    <div v-if="requestAttachments !== null && currentView === 5">
                      <div class="mx-auto flex justify-center mb-2">
                        <button @click=" downloadAllAttachments()" class="shadow mb-2 inline-block box-shadow text-size-sm px-20 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">Download All</button>
                      </div>
                      <table id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                          <thead>
                            <tr class="bg-emerald-600 text-size-xs pointer-events-none" style="">
                              <td class="py-2.5 px-2 text-left text-white font-bold">#</td>
                              <td class="py-2.5 px-2 text-left text-white font-bold">File Name</td>
                              <td class="py-2.5 px-2 border-l text-left text-white font-bold">File Size</td>
                              <td class="py-2.5 px-2 border-l text-center text-white font-bold">Action</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(data, i) in requestAttachments" :key="i" class=" text-gray-600 text-size-sm" style="">
                              <td class="py-2.5 px-2 border text-left">{{ (i + 1) }}</td>
                              <td class="py-2.5 px-2 border text-left">{{ data.realName }}</td>
                              <td class="py-2.5 px-2 border text-left">{{ fileSizeFormatter(data.size) }}</td>
                              <td class="py-2.5 px-2 border text-center">
                                <button @click="downloadAttachment(data.realName, data.name)" class=" bg-emerald-500 px-2 py-1 shadow-sm text-white">Download</button>
                              </td>
                            </tr>
                          </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- the loader modal -->
    <Loader ref="loaderRef"/>
    <!-- the message loader -->
    <Message :title="title" :message="processMessage" ref="messageRef"/>
  </div>
</template>

<script>
import Navbar from '@/components/AdminNavbar.vue'
import Sidebar from '@/components/AdminSideBar.vue'
import { mapState } from 'vuex'
import { ajax } from 'rxjs/ajax'
import { concat } from 'rxjs'
import Message from './modals/Message.vue';
import Loader from './modals/Loader.vue';

export default {
  computed: {
    ...mapState([
      'employee'
    ])
  },
  components: {
    Navbar,
    Sidebar,
    Loader,
    Message
  },
  setup () {
    return {
    }
  },
  name: 'admin-home',
  props: {
  },
  data: () => {
    return {
      advance: null,
      hotelNeed: null,
      hotel: null,
      mieAdvance: null,
      mieIntlAdvance: null,
      lodgingAdvance: null,
      otherAdvance: null,
      tor: null,
      requestAttachments: [],
      title: 'Message Error',
      processMessage: '',
      requestData: null,
      documentsView: [true, false, false, false, false],
      currentView: 1,
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    }
  },
  mounted () {
    // removing the submenu items selection
    if (this.employee !== null) {
      // this.$store.commit('mutateActiveListItemIndex', 500)
      // this.$store.commit('mutateSelectedSubMenuItem', 500)
      // get the request
      this.getRequest()
    }
  },
  methods: {
    downloadAllAttachments () {
        this.$refs.loaderRef.openModal()
        const numberOfFiles = this.requestAttachments.length

        const observables = []

        for (let i = 0; i < numberOfFiles; i++) {
          const file = this.requestAttachments[i]
          const generatedFilename = file.name
          const realFilename = file.realName
          const fileObservable = ajax({
            method: 'GET',
            url: process.env.VUE_APP_BASE_URL + '/request/downloadAttachment?filename=' + generatedFilename,
            headers: {
              Authorization: `Bearer ${this.employee.token}`,
              filename: realFilename
            },
            responseType: 'blob'
          })

          observables.push(fileObservable)
        }

        const observable = concat(...observables)

        
        const observer = {
          next: (response) => {
            const blob = response.response
            // const headers = response.responseHeaders
            const filename = response.request.headers.filename
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', filename || url.split('/').pop());
            document.body.appendChild(link);
            link.click();
            link.remove();
          },
          error: () => {
            // showing the dialog for the error
            // close the loader modal
            this.$refs.loaderRef.closeModal()
            // showing the dialog for the error
            this.processMessage = 'Failed to download the attached files.'
            // open the error modal
            this.$refs.messageRef.closeModal()
          },
          complete: () => {
            this.$refs.loaderRef.closeModal()
          }
        }

        observable.subscribe(observer)
    },
    downloadAttachment (originalFilename, generatedFilename) {
      // open the loading modal
      this.$refs.loaderRef.openModal()

      const observable = ajax({
        method: 'GET',
        url: process.env.VUE_APP_BASE_URL + '/request/downloadAttachment?filename=' + generatedFilename,
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        },
        responseType: 'blob'
      })

      const observer = {
        next: (response) => {
          const blob = response.response
          // const headers = response.responseHeaders
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', originalFilename || url.split('/').pop());
          document.body.appendChild(link);
          link.click();
          link.remove();
        },
        error: () => {
          // showing the dialog for the error
          // close the loader modal
          this.$refs.loaderRef.closeModal()
          // showing the dialog for the error
          this.processMessage = 'Failed to download the attached file.'
          // open the error modal
          this.$refs.messageRef.closeModal()
        },
        complete: () => {
          // open the loading modal
          this.$refs.loaderRef.closeModal()
        }
      }

      observable.subscribe(observer)
    },
    // calculates the grand totals of all the advances
    calculateAdvanceTotals () {
      let grandTotal = 0

      // get the advance grand totals
      let mieTotal = 0

      if (this.mieAdvance !== null && Array.isArray(this.mieAdvance) && this.mieAdvance.length > 0) {
        const size = this.mieAdvance.length
        mieTotal = parseFloat(this.mieAdvance[(size - 1)].advance) - parseFloat(this.mieAdvance[0].advance) * 0.25 -  parseFloat(this.mieAdvance[this.mieAdvance.length - 2].advance) * 0.25
      }

      let mieIntlTotal = 0
      if (this.mieIntlAdvance !== null && Array.isArray(this.mieIntlAdvance) && this.mieIntlAdvance.length > 0) {
        const size = this.mieIntlAdvance.length
        mieIntlTotal = parseFloat(this.mieIntlAdvance[(size - 1)].advance)
      }

      let lodgingCost = 0
      if (this.lodgingAdvance !== null && Array.isArray(this.lodgingAdvance) && this.lodgingAdvance.length > 0) {
        const size = this.lodgingAdvance.length
        const total = this.lodgingAdvance[(size-1)].total

        lodgingCost = parseFloat(total)
      }

      let otherTotal = 0
      if (this.otherAdvance !== null && Array.isArray(this.otherAdvance) && this.otherAdvance.length > 0) {
        const size = this.otherAdvance.length
        const total = this.otherAdvance[(size-1)].total

        otherTotal = parseFloat(total)
      }

      grandTotal = mieTotal + mieIntlTotal + lodgingCost + otherTotal 
      return grandTotal
    },
    // getting the pending activity approvals
    getRequest () {
      const requestId = localStorage.getItem('requestId')
      this.showRequests = false
      const observable = ajax({
        url: process.env.VUE_APP_BASE_URL + `/request/findById?id=${requestId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      })
      const observer = {
        next: (response) => {
          const data = response.response
          this.requestData = data
          const size = data.length
          if (size > 0) {
            this.advance = data[0].advance.advance
            this.hotelNeed = data[0].advance.hotelNeed
            this.hotel = data[0].advance.hotel
            this.mieAdvance = data[0].advance.mie
            this.mieIntlAdvance = data[0].advance.mieIntl
            this.lodgingAdvance = data[0].advance.lodging
            this.otherAdvance = data[0].advance.other
            this.tor = data[0].advance.tor
            this.requestAttachments = data[0].attachments
          }
          this.showRequests = true
        },
        error: (err) => {
          const status = err.status
          if (status === 401) {
            this.$router.push('/')
          }
        },
        complete: () => {}
      }
      observable.subscribe(observer)
    },
    // file size converter
    fileSizeFormatter (bytes) {
      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) {
        return '0 Bytes'
      }
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
    },
    // switch the view
    switchView (index) {
      this.documentsView[(index - 1)] = true
      this.currentView = index
    },
    // date difference in days
    dateDiff (startDate, endDate) {
      const date2 = new Date(endDate)
      const date1 = new Date(startDate)

      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      // remove the time and time-zone details.
      const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
      const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

      return (Math.floor((utc2 - utc1) / _MS_PER_DAY) + 1);
    },
    // format the table data
    formatData (data) {
      var formattedData
      // check if it is a number
      if (data !== null && data !== undefined && !isNaN(data) && String(data).length > 2) {
        // format the number to a currency format
        var currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'MWK' })
        formattedData = currencyFormat.format(String(data)).substring(4)
      } else if (data !== null && data !== undefined && data.toString().includes('-') && !isNaN(Date.parse(data))) {
        // check if the data is a date
        // formating the dates supplied
        var textDate = new Date(data)
        var formattedTextDate = textDate.getDate() + ' ' + this.months[textDate.getMonth()] + ' ' + textDate.getFullYear()
        formattedData = formattedTextDate
      } else {
        formattedData = data
      }
      return formattedData
    },
    // calculates the date difference in days for the schedule days
    dateDiffInDays () {
        // the date which the activity starts
        const days = []
        const scheduleSize = this.scheduleData.length

        for (let i = 0; i < scheduleSize; i++) {
          const startDate = this.scheduleData[i].startDate
          days.push(startDate)
        }

        // sort the days and get the minimum day
        days.sort()
        const miniDate = days[0]
        const dateFrom = new Date(miniDate)


        // today's date
        const dateTo = new Date()

        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // remove the time and time-zone details.
        const utc1 = Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate());
        const utc2 = Date.UTC(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
      },
    // move to the next page
    back () {
      this.$router.push('/create-request-5')
    },
    // expands or collapses the main body
    expand (value) {
      this.expandBody = value
    },
    // submits the signature
    submit () {
      if (!this.documentsView.every(value => value === true)) {
        var message = 'Before resubmitting your request: \n'
        if (this.documentsView[1] === false) {
          message += '  - Please view & verify your Hotel Need. \n'
        }
        if (this.documentsView[2] === false) {
          message += '  - Please view & verify your Advances. \n'
        }
        if (this.documentsView[3] === false) {
          message += '  - Please view  & verify the Terms of Reference. \n'
        }
        if (this.documentsView[4] === false) {
          message += '  - Please view  & verify the Uploaded Files. \n'
        }
        message += 'Click on the buttons above to view & verify the entered details.'
        this.processMessage = message
        // open the error modal
        this.$refs.messageRef.openModal()
      } else {
        // open the loading modal
        this.$refs.loaderRef.openModal()
        const advanceData = {
          advance: this.advance,
          hotelNeed: this.hotelNeed,
          mie: this.mieAdvance,
          hotel: this.hotel,
          lodging: this.lodgingAdvance,
          other: this.otherAdvance,
          tor: this.tor
        }

        const observable = ajax({
          url: process.env.VUE_APP_BASE_URL + '/request/insert',
          method: 'POST',
          body: {
            employee_id: this.employee.id,
            attachments: JSON.stringify(this.requestAttachments),
            advance: JSON.stringify(advanceData)
          },
          headers: {
            Authorization: `Bearer ${this.employee.token}`
          }
        })

        const observer = {
          next: () => {
            // move to the profile page
            this.$router.push('/employee-requests')
          },
          error: () => {
            // close the loader modal
            this.$refs.loaderRef.closeModal()
            // showing the dialog for the error
            this.processMessage = 'Failed to submit the signature, please try again.'
            // open the error modal
            this.$refs.messageRef.openModal()
          },
          complete: () => {
            this.$refs.loaderRef.closeModal()
            
          }
        }

        observable.subscribe(observer)
      }
    }
  }
}
</script>

<style>

#chart-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 40vh;
}

</style>
