<template>
    <AdminTrack75Liquidation></AdminTrack75Liquidation>
</template>
<script>
    import AdminTrack75Liquidation from '@/components/AdminTrack75Liquidation.vue'
    export default {
        name: 'Admin-track-liquidation',
        components: {
            AdminTrack75Liquidation
        }
    }
</script>