<template>
  <div class="w-full h-screen bg-white overflow-x-hidden">
    <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
    <div>
      <Sidebar class="sidebar"/>
      <div :class="[!expandBody ? 'main px-5 space-y-4 margin' : 'main px-5 space-y-4']">
        <!-- the top route and username grid -->
        <div class="justify-between flex pt-6 text-size-md font-medium text-gray-500 w-full">
          <span><span class=" text-emerald-500">>Home</span> > Approved Liquidations</span>
          <span v-if="employee !== null">{{ employee.firstname }} {{ employee.surname }}</span>
        </div>
          <div>
          <!-- Timeline -->
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-4 mb-4 space-x-1 items-center">
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,8.01c-2.21,0-4,1.79-4,4s1.79,4,4,4,4-1.79,4-4-1.79-4-4-4Zm0,6c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM20,0H4C1.79,0,0,1.8,0,4.01v1C0,7.21,1.79,9.01,4,9.01v10c0,2.76,2.24,5,5,5h6c2.76,0,5-2.24,5-5V9.01c2.21,0,4-1.79,4-4v-1C24,1.8,22.21,0,20,0Zm2,5c0,1.1-.9,2-2,2v-2c0-.55-.45-1-1-1s-1,.45-1,1v14c0,1.65-1.35,3-3,3h-6c-1.65,0-3-1.35-3-3V5.01c0-.55-.45-1-1-1s-1,.45-1,1v2c-1.1,0-2-.9-2-2v-1c0-1.1,.9-2,2-2H20c1.1,0,2,.9,2,2v1Z"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M21,0H3C1.35,0,0,1.35,0,3v6H4v15H20V9h4V3c0-1.65-1.35-3-3-3Zm-1,7v-3h-2V22H6V4h-2v3H2V3c0-.55,.45-1,1-1H21c.55,0,1,.45,1,1V7h-2Zm-8,1c-2.21,0-4,1.79-4,4s1.79,4,4,4,4-1.79,4-4-1.79-4-4-4Zm0,6c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2Z"/></svg> -->
              <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m14,1.5c0-.828.672-1.5,1.5-1.5s1.5.672,1.5,1.5-.672,1.5-1.5,1.5-1.5-.672-1.5-1.5Zm8.5,3.5c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5,1.5-.672,1.5-1.5-.672-1.5-1.5-1.5Zm.5,7.5v8c0,1.995-2.58,3.5-6,3.5-2.151,0-3.968-.596-5.022-1.534-1.217,1.01-3.353,1.534-5.478,1.534-3.23,0-6.5-1.202-6.5-3.5V4.5C0,2.202,3.27,1,6.5,1s6.5,1.202,6.5,3.5v5.353c1.047-.534,2.433-.853,4-.853,3.42,0,6,1.505,6,3.5Zm-2,2.647c-1.047.534-2.433.853-4,.853s-2.953-.319-4-.853v1.353c0,.529,1.519,1.5,4,1.5s4-.971,4-1.5v-1.353ZM11,7.091c-1.226.601-2.867.909-4.5.909s-3.274-.308-4.5-.909v1.409c0,.436,1.577,1.5,4.5,1.5s4.5-1.064,4.5-1.5v-1.409ZM2,11.091v1.409c0,.436,1.577,1.5,4.5,1.5s4.5-1.064,4.5-1.5v-1.409c-1.226.601-2.867.909-4.5.909s-3.274-.308-4.5-.909Zm0,5.409c0,.436,1.577,1.5,4.5,1.5s4.5-1.064,4.5-1.5v-1.409c-1.226.601-2.867.909-4.5.909s-3.274-.308-4.5-.909v1.409Zm11-4c0,.529,1.519,1.5,4,1.5s4-.971,4-1.5-1.519-1.5-4-1.5-4,.971-4,1.5ZM2,4.5c0,.436,1.577,1.5,4.5,1.5s4.5-1.064,4.5-1.5-1.577-1.5-4.5-1.5-4.5,1.064-4.5,1.5Zm9,16v-1.409c-1.226.601-2.867.909-4.5.909s-3.274-.308-4.5-.909v1.409c0,.436,1.577,1.5,4.5,1.5s4.5-1.064,4.5-1.5Zm10,0v-1.353c-1.047.534-2.433.853-4,.853s-2.953-.319-4-.853v1.353c0,.529,1.519,1.5,4,1.5s4-.971,4-1.5Zm-4.555-12.668c.17.114.363.168.554.168.323,0,.64-.156.833-.445l4-6c.306-.459.182-1.081-.277-1.387-.46-.308-1.081-.183-1.387.277l-4,6c-.306.459-.182,1.081.277,1.387Z"/>
              </svg>
              <p class="text-left text-size-md font-medium text-gray-600"> {{liquidationData.length}} Approved Liquidations</p>
            </div>
            <!-- {{ liquidationData[0] }} -->
              <!-- The pending activity approvals -->
              <div class="mx-8 mb-5 mt-5 border shadow px-4 py-8 grid grid-flow-row grid-cols-1 grid-rows-1 gap-x-4 gap-y-4 sm:gap-y-4">
                <div class=" flex">
                  <input
                    v-model="filter"
                    type="text"
                    placeholder="Search..."
                    required class="shadow-sm bg-gray-50 text-gray-600 w-full text-size border px-2 py-3 border-gray-300  focus:outline-none focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 "/>
                </div>

                <div class=" overflow-x-auto">
                  <table id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                    <thead>
                      <tr class=" bg-emerald-600 text-size-xs pointer-events-none" style="">
                        <td class="py-3 px-2 text-left text-white font-bold">Liquidation Date</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Request Reason</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Departure Date</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Return Date</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Currency</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Advance Amount (75%)</td>
                        <td class="py-3 px-2 border text-center text-white font-bold">Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, i) in filteredRows" :key="i" class="text-gray-500 text-size-sm" style="">
                        <td class="py-3 px-2 border text-left">{{ data.liquidation_date }}</td>
                        <td class="py-3 px-2 border text-left">{{ data.advance.advance.reasonOfTravel.substring(0, 32) }}...</td>
                        <td class="py-3 px-2 border text-left">{{ formatData(data.advance.advance.departureDate)}}</td>
                        <td class="py-3 px-2 border text-left">{{ formatData(data.advance.advance.returnDate)}}</td>
                        <td class="py-3 px-2 border text-left">{{ data.advance.advance.budgetSource.map((data) => data.name).join(', ')}}</td>
                        <td class="py-3 px-2 border text-left">{{ formatData(data.advance.mie[(data.advance.mie.length - 1)].advance)}}</td>
                        <td class="py-3 px-2 border text-center">
                          <div class="flex items-center justify-center">
                            <button @click="trackLiquidation(data.id)" class="bg-emerald-500 text-white px-4 py-1">Track</button>
                            <button @click="viewLiquidation(data.id)" class="bg-emerald-500 text-white px-4 py-1">View</button>
                            <button @click="downloadLiquidation(data.id)" class="bg-emerald-500 text-white px-4 py-1">Download</button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="flex mb-5">
                <div class=" flex mx-auto items-center">
                  <div v-if="startRow !== 0" @click="movePages(-1)" class=" bg-emerald-600 px-1 py-1 cursor-pointer hover:bg-emerald-300 focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div v-if="(startRow+rowsPerPage) < liquidationData.length" @click="movePages(1)" class=" bg-emerald-600 px-1 py-1 cursor-pointer border-l hover:bg-emerald-300 focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
     <!-- the loader modal -->
   <Loader ref="loaderRef"/>
    <!-- the message loader -->
    <Message :title="title" :message="processMessage" ref="messageRef"/>
  </div>
</template>

<script>
import Navbar from '@/components/EmployeeNavbar.vue'
import Sidebar from '@/components/EmployeeSideBar.vue'
import Loader from './modals/Loader.vue'
import Message from './modals/Message.vue'
import { mapState } from 'vuex'
import { ajax } from 'rxjs/ajax'
import { retry, concat } from 'rxjs'

export default {
  computed: {
    ...mapState([
      'employee'
    ]),
    filteredRows () {
      return this.liquidationData.filter((row) => {
        const searchText = this.filter.toLowerCase()

        return JSON.stringify(row).toLowerCase().includes(searchText)
      }).slice(this.startRow, this.startRow + this.rowsPerPage)
    }
  },
  components: {
    Navbar,
    Sidebar,
    Loader,
    Message
  },
  setup () {
    return {
    }
  },
  name: 'liquidation-approval-history',
  props: {
  },
  data: () => {
    return {
      filter: '',
      startRow: 0,
      rowsPerPage: 10,
      endPageItemIndex: 10,
      showRequests: false,
      expandBody: false,
      liquidationData: [],
      title: '',
      processMessage: '',
      BASE_URL: process.env.VUE_APP_BASE_URL,
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    }
  },
  mounted () {
    // removing the submenu items selection
    this.$store.commit('mutateActiveListItemIndex', 600)
    this.$store.commit('mutateSelectedSubMenuItem', 609)
    // getting the pending activity approvals
    this.getLiquidationsApprovedByEmployee()
  },
  methods: {
    downloadLiquidation (id) {
        this.$refs.loaderRef.openModal()
        const observables = []
        const urls = [`/liquidation/downloadTripReport?id=${id}`, `/liquidation/downloadExpenseReport?id=${id}`]
        const filename = [`trip-report-${id}.pdf`, `expense-report-${id}.pdf`]

        for (let i = 0; i < 2; i++) {
          const realFilename = filename[i]
          const url = urls[i]
          const fileObservable = ajax({
            method: 'GET',
            url: process.env.VUE_APP_BASE_URL + url,
            headers: {
              Authorization: `Bearer ${this.employee.token}`,
              filename: realFilename
            },
            responseType: 'blob'
          })

          observables.push(fileObservable)
        }

        const observable = concat(...observables)

        
        const observer = {
          next: (response) => {
            const blob = response.response
            // const headers = response.responseHeaders
            const filename = response.request.headers.filename
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', filename || url.split('/').pop());
            document.body.appendChild(link);
            link.click();
            link.remove();
          },
          error: () => {
            // showing the dialog for the error
            // close the loader modal
            this.$refs.loaderRef.closeModal()
            // showing the dialog for the error
            this.processMessage = 'Failed to download the attached files.'
            // open the error modal
            this.$refs.messageRef.openModal()
          },
          complete: () => {
            this.$refs.loaderRef.closeModal()
          }
        }

        observable.subscribe(observer)
    },
    // moves the pages of the pagenation
    movePages (amount) {
      var newStartRow = this.startRow + (amount * this.rowsPerPage)
      // only move if its greater than 0
      if (newStartRow >= 0 && newStartRow < this.liquidationData.length) {
        this.startRow = newStartRow
      }
    },
    trackLiquidation (id) {
      localStorage.setItem('liquidationId', id)
      this.$router.push('/employee-track-approved-liquidation')
    },
    viewLiquidation (id) {
      localStorage.setItem('liquidationId', id)
      this.$router.push('/employee-view-liquidation')
    },
    // format the table data
    formatData (data) {
      var formattedData
      // check if it is a number
      if (data !== null && data !== undefined && !isNaN(data) && String(data).length > 2) {
        // format the number to a currency format
        var currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'MWK' })
        formattedData = currencyFormat.format(String(data)).substring(4)
      } else if (data !== null && data !== undefined && data.toString().includes('-') && !isNaN(Date.parse(data))) {
        // check if the data is a date
        // formating the dates supplied
        var textDate = new Date(data)
        var formattedTextDate = textDate.getDate() + ' ' + this.months[textDate.getMonth()] + ' ' + textDate.getFullYear()
        formattedData = formattedTextDate
      } else {
        formattedData = data
      }
      return formattedData
    },
    // expands or collapses the main body
    expand (value) {
      this.expandBody = value
    },
    // getting the pending activity approvals
    getLiquidationsApprovedByEmployee () {
      this.showRequests = false
      const observable = ajax({
        url: this.BASE_URL + '/liquidation/findApproved',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      }).pipe(
        retry(1)
      )
      const observer = {
        next: (response) => {
          const data = response.response
          this.liquidationData = data
          this.showRequests = true
        },
        error: (err) => {
          const status = err.status
          if (status === 401) {
            this.$router.push('/')
          }
        },
        complete: () => {}
      }
      observable.subscribe(observer)
    }
  }
}
</script>

<style>

#chart-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 40vh;
}

</style>
