<template>
    <AdminPending25Liquidations></AdminPending25Liquidations>
</template>
<script>
    import AdminPending25Liquidations from '@/components/AdminPending25Liquidations.vue'
    export default {
        name: 'admin-track-Pending-request',
        components: {
            AdminPending25Liquidations
        }
    }
</script>