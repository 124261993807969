<template>
    <PendingPayments></PendingPayments>
</template>
<script>
    import PendingPayments from '@/components/EmployeePendingPayments.vue'
    export default {
        name: 'approved-liquidations',
        components: {
            PendingPayments
        }
    }
</script>