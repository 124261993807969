<template>
    <EmployeeTrackLiquidation></EmployeeTrackLiquidation>
</template>
<script>
    import EmployeeTrackLiquidation from '@/components/EmployeeTrackApprovedLiquidation.vue'
    export default {
        name: 'employee-track-liquidation',
        components: {
            EmployeeTrackLiquidation
        }
    }
</script>