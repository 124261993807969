<template>
    <AdminView25Liquidation></AdminView25Liquidation>
</template>
<script>
    import AdminView25Liquidation from '@/components/AdminView25Liquidation.vue'
    export default {
        name: 'Admin-sign',
        components: {
            AdminView25Liquidation
        }
    }
</script>