<template>
    <EmployeeViewRequest></EmployeeViewRequest>
</template>
<script>
    import EmployeeViewRequest from '@/components/EmployeeProcessRequest.vue'
    export default {
        name: 'employee-process-request',
        components: {
            EmployeeViewRequest
        }
    }
</script>