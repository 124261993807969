<template>
    <AdminApproved25Liquidations></AdminApproved25Liquidations>
</template>
<script>
    import AdminApproved25Liquidations from '@/components/AdminApproved25Liquidations.vue'
    export default {
        name: 'admin-track-approved-request',
        components: {
            AdminApproved25Liquidations
        }
    }
</script>