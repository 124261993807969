<template>
    <ApprovedLiquidation></ApprovedLiquidation>
</template>
<script>
    import ApprovedLiquidation from '@/components/EmployeeApprovedLiquidations.vue'
    export default {
        name: 'employee-approved-liquidation',
        components: {
            ApprovedLiquidation
        }
    }
</script>