<template>
  <div class="w-full h-screen bg-white overflow-x-hidden">
    <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
    <div>
      <Sidebar class="sidebar"/>
      <div :class="[!expandBody ? 'main px-5 space-y-2 margin' : 'main px-5 space-y-2']">
        <!-- the top route and username grid -->
        <div class="justify-between flex pt-5 text-size-md font-medium text-gray-500 w-full">
          <span class=" text-emerald-500" >> Home</span>
          <span v-if="employee !== null">{{ employee.firstname }} {{ employee.surname }} </span>
        </div>
        <!-- the requests charts -->
        <div class="grid gap-6 grid-rows-1 grid-cols-1">
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg mt-4 bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-6 mb-4 items-center space-x-1">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,24H3a3,3,0,0,1-3-3V0H2V21a1,1,0,0,0,1,1H24Z"/><rect x="14" y="11" width="2" height="9"/><rect x="6" y="11" width="2" height="9"/><rect x="18" y="6" width="2" height="14"/><rect x="10" y="6" width="2" height="14"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m12,12c3.309,0,6-2.691,6-6S15.309,0,12,0s-6,2.691-6,6,2.691,6,6,6Zm0-10c2.206,0,4,1.794,4,4s-1.794,4-4,4-4-1.794-4-4,1.794-4,4-4Zm9,17v5h-2v-5c0-1.654-1.346-3-3-3h-2l-1.333,2,1.333,6h-4l1.333-6-1.333-2h-2c-1.654,0-3,1.346-3,3v5h-2v-5c0-2.757,2.243-5,5-5h8c2.757,0,5,2.243,5,5Z"/>
              </svg> -->
              <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M18.4,7.379a1.128,1.128,0,0,1-.769-.754h0a8,8,0,1,0-15.1,5.237A1.046,1.046,0,0,1,2.223,13.1,5.5,5.5,0,0,0,.057,18.3,5.622,5.622,0,0,0,5.683,23H11a1,1,0,0,0,1-1h0a1,1,0,0,0-1-1H5.683a3.614,3.614,0,0,1-3.646-2.981,3.456,3.456,0,0,1,1.376-3.313A3.021,3.021,0,0,0,4.4,11.141a6.113,6.113,0,0,1-.073-4.126A5.956,5.956,0,0,1,9.215,3.05,6.109,6.109,0,0,1,9.987,3a5.984,5.984,0,0,1,5.756,4.28,2.977,2.977,0,0,0,2.01,1.99,5.934,5.934,0,0,1,.778,11.09.976.976,0,0,0-.531.888h0a.988.988,0,0,0,1.388.915c4.134-1.987,6.38-7.214,2.88-12.264A6.935,6.935,0,0,0,18.4,7.379Z"/><path d="M18.707,16.707a1,1,0,0,0,0-1.414l-1.586-1.586a3,3,0,0,0-4.242,0l-1.586,1.586a1,1,0,0,0,1.414,1.414L14,15.414V23a1,1,0,0,0,2,0V15.414l1.293,1.293a1,1,0,0,0,1.414,0Z"/></svg>
              <p class="text-left text-size-md font-medium text-gray-600">Signature Upload <span class="text-red-400">(Images Only)</span></p>
            </div>
            <!--  chart -->
            <div class="ml-8 pb-8">
              <div
                class="ring-1 py-2 text-left cursor-pointer ring-gray-200 shadow-md mr-10 pl-1 chart-height"
              >
                <div class="px-4 py-2 space-y-2.5">
                  <div>
                    <span class="mx-4 text-size-md font-medium text-gray-500">Upload Your Signature</span>
                   </div>
                  <div class="mx-4 mb-5 mt-0.5 border shadow px-4 py-6 grid grid-flow-row grid-cols-1 ">
                    <!-- the signature -->
                    <div class=" border-gray-600 overflow-hidden bg-red">
                        <!-- show the signature pad -->
                        <div class="flex w-full mx-auto justify-center">
                          <form
                            :action="`${BASE_URL}/signature/upload`"
                            id="form-2">
                              <button
                                class="shadow pointer-events-none inline-block box-shadow text-size-sm px-16 py-2 bg-emerald-500  text-white font-medium leading-tight transform focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                                  Click here to Upload Your Signature
                              </button>
                          </form>
                        </div>
                    </div>
                  </div>
                <div class="w-full flex text-center mx-auto pt-1">
                    <div class="flex-1 mb-2">
                      <form
                        :action="`${BASE_URL}/signature/upload`"
                        id="form-2">
                          <button
                            class="shadow inline-block box-shadow text-size-sm pointer-events-none px-10 py-2 bg-emerald-500  text-white font-medium leading-tight transform focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                              Upload Signature
                          </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- the loader modal -->
    <Loader ref="loaderRef"/>
    <!-- the message loader -->
    <Message :title="title" :message="processMessage" ref="messageRef"/>
  </div>
</template>

<script>
import Navbar from '@/components/EmployeeNavbar.vue'
import Sidebar from '@/components/EmployeeSideBar.vue'
import { mapState } from 'vuex'
import { ajax } from 'rxjs/ajax'
import Message from './modals/Message.vue';
import Loader from './modals/Loader.vue';
import Dropzone from 'dropzone'

export default {
  computed: {
    ...mapState([
      'employee'
    ])
  },
  components: {
    Navbar,
    Sidebar,
    Loader,
    Message
  },
  setup () {
    return {
    }
  },
  name: 'admin-home',
  props: {
  },
  data: () => {
    return {
      title: 'Process Error',
      processMessage: '',
      employeeData: null,
      disabled: false,
      signatureLink: null,
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
      },
      signature: null,
      BASE_URL: process.env.VUE_APP_BASE_URL
    }
  },
  mounted () {
    // removing the submenu items selection
    if (this.employee !== null) {
      this.$store.commit('mutateActiveListItemIndex', 100)
      this.$store.commit('mutateSelectedSubMenuItem', 102)

      // the second dropzone
      const myDropzone2 = new Dropzone('#form-2', {
        headers: { Authorization: `Bearer ${this.employee.token}` },
        disablePreviews: true,
        acceptedFiles: "image/jpeg, image/png, image/jpg"
      })
      // callback for the file added
      myDropzone2.on('addedfile', (file) => {
        // open the loading modal
        if (file.type.includes('png') || file.type.includes('jpg')  || file.type.includes('jpeg')) {
          this.$refs.loaderRef.openModal()
        } else {
          myDropzone2.removeFile(file);
        }
      })
      // the second drop zone
      myDropzone2.on('complete', (file) => {
        if (file.xhr !== undefined) {
          const fileDetails = JSON.parse(file.xhr.response)
          // const realFilename = fileDetails.realName
          const renamedFilename = fileDetails.renamedFilename
          
          // insert the signaturev dcetails
          this.submit(renamedFilename)
        }
      })
      myDropzone2.on("error", () => {
        // close the loader modal
        this.$refs.loaderRef.closeModal()
        // showing the dialog for the error
        this.processMessage = 'The submission failed, please make sure the file is an image (JPG/PNG/JPEG).'
        // open the error modal
        this.$refs.messageRef.openModal()
      });
    }
  },
  methods: {
    // expands or collapses the main body
    expand (value) {
      this.expandBody = value
    },
    // submits the signature
    submit (filename) {
      const observable = ajax({
        url: process.env.VUE_APP_BASE_URL + '/signature/insertFile',
        method: 'POST',
        body: {
          employee_id: this.employee.id,
          filename: filename
        },
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      })

      const observer = {
        next: () => {
          // move to the profile page
          this.$router.push('/employee-profile')
        },
        error: () => {
          // close the loader modal
          this.$refs.loaderRef.closeModal()
          // showing the dialog for the error
          this.processMessage = 'Failed to submit the signature, please try again.'
          // open the error modal
          this.$refs.messageRef.openModal()
        },
        complete: () => {
          this.$refs.loaderRef.closeModal()
        }
      }

      observable.subscribe(observer)
    }
  }
}
</script>

<style>

#chart-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 40vh;
}

</style>
