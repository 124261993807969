<template>
    <RejectedLiquidations></RejectedLiquidations>
</template>
<script>
    import RejectedLiquidations from '@/components/EmployeeRejectedLiquidations.vue'
    export default {
        name: 'approved-liquidations',
        components: {
            RejectedLiquidations
        }
    }
</script>