<template>
    <AdminRejected25Liquidations></AdminRejected25Liquidations>
</template>
<script>
    import AdminRejected25Liquidations from '@/components/AdminRejected25Liquidations.vue'
    export default {
        name: 'admin-track-rejected-request',
        components: {
            AdminRejected25Liquidations
        }
    }
</script>