<template>
    <AdminApproved75Liquidations></AdminApproved75Liquidations>
</template>
<script>
    import AdminApproved75Liquidations from '@/components/AdminApproved75Liquidations.vue'
    export default {
        name: 'admin-track-approved-request',
        components: {
            AdminApproved75Liquidations
        }
    }
</script>