<template>
  <div class="w-full h-screen bg-white overflow-x-hidden">
    <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
    <div>
      <Sidebar class="sidebar"/>
      <div :class="[!expandBody ? 'main px-5 space-y-2 margin mb-16' : 'main px-5 space-y-2 mb-16']">
        <!-- the top route and username grid -->
        <div class="justify-between flex pt-5 text-size-md font-medium text-gray-500 w-full">
          <span class=" text-emerald-500" >> Home > Edit Request Step 3</span>
          <span v-if="employee !== null">{{ employee.firstname }} {{ employee.surname }} </span>
        </div>
        <!-- the requests charts -->
        <div class="grid gap-6 grid-rows-1 grid-cols-1">
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg mt-4 bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-6 mb-4 items-center space-x-1">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,24H3a3,3,0,0,1-3-3V0H2V21a1,1,0,0,0,1,1H24Z"/><rect x="14" y="11" width="2" height="9"/><rect x="6" y="11" width="2" height="9"/><rect x="18" y="6" width="2" height="14"/><rect x="10" y="6" width="2" height="14"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m12,12c3.309,0,6-2.691,6-6S15.309,0,12,0s-6,2.691-6,6,2.691,6,6,6Zm0-10c2.206,0,4,1.794,4,4s-1.794,4-4,4-4-1.794-4-4,1.794-4,4-4Zm9,17v5h-2v-5c0-1.654-1.346-3-3-3h-2l-1.333,2,1.333,6h-4l1.333-6-1.333-2h-2c-1.654,0-3,1.346-3,3v5h-2v-5c0-2.757,2.243-5,5-5h8c2.757,0,5,2.243,5,5Z"/>
              </svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M18.4,7.379a1.128,1.128,0,0,1-.769-.754h0a8,8,0,1,0-15.1,5.237A1.046,1.046,0,0,1,2.223,13.1,5.5,5.5,0,0,0,.057,18.3,5.622,5.622,0,0,0,5.683,23H11a1,1,0,0,0,1-1h0a1,1,0,0,0-1-1H5.683a3.614,3.614,0,0,1-3.646-2.981,3.456,3.456,0,0,1,1.376-3.313A3.021,3.021,0,0,0,4.4,11.141a6.113,6.113,0,0,1-.073-4.126A5.956,5.956,0,0,1,9.215,3.05,6.109,6.109,0,0,1,9.987,3a5.984,5.984,0,0,1,5.756,4.28,2.977,2.977,0,0,0,2.01,1.99,5.934,5.934,0,0,1,.778,11.09.976.976,0,0,0-.531.888h0a.988.988,0,0,0,1.388.915c4.134-1.987,6.38-7.214,2.88-12.264A6.935,6.935,0,0,0,18.4,7.379Z"/><path d="M18.707,16.707a1,1,0,0,0,0-1.414l-1.586-1.586a3,3,0,0,0-4.242,0l-1.586,1.586a1,1,0,0,0,1.414,1.414L14,15.414V23a1,1,0,0,0,2,0V15.414l1.293,1.293a1,1,0,0,0,1.414,0Z"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m17,0H7C4.243,0,2,2.243,2,5v14c0,2.757,2.243,5,5,5h10c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5ZM7,2h10c1.654,0,3,1.346,3,3H4c0-1.654,1.346-3,3-3Zm10,20H7c-1.654,0-3-1.346-3-3V7h16v12c0,1.654-1.346,3-3,3Zm0-11c0,.553-.448,1-1,1h-8c-.552,0-1-.447-1-1s.448-1,1-1h8c.552,0,1,.447,1,1Zm-4,4c0,.553-.448,1-1,1h-4c-.552,0-1-.447-1-1s.448-1,1-1h4c.552,0,1,.447,1,1Z"/></svg> -->
              <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m13,5v2H5v-2h8Zm-7,6h6v-2h-6v2Zm17,4.764v5.236c0,1.654-1.346,3-3,3H1V0h17c1.654,0,3,1.346,3,3v8.764l2,4ZM3,22h12V2H3v20Zm18-5.764l-2-4V3c0-.551-.448-1-1-1h-1v20h3c.552,0,1-.449,1-1v-4.764Z"/>
              </svg>
              <p class="text-left text-size-md font-medium text-gray-600">Edit Request</p>
            </div>
            <!-- The activity creation steps -->
            <div class="flex items-center col-span-full mx-4 mt-5 pr-2">
              <span class="ml-4 mr-0.5 cursor-pointer text-size-sm font-medium bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">1</span>
              <div class="flex-auto cursor-pointer text-size border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="ml-0.5 mr-0.5 cursor-pointer text-size-sm font-medium bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">2</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="ml-0.5 mr-0.5 animate-pulse cursor-pointer text-size-sm font-medium bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">3</span>
              <div class="flex-auto animate-pulse cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="cursor-pointer mx-0.5 text-size-sm font-medium ring-emerald-600 ring-2 text-gray-500 py-0 px-1">4</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="cursor-pointer mx-0.5 text-size-sm ring-emerald-600 font-medium ring-2 text-gray-500 py-0 px-1">5</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="cursor-pointer text-size-sm font-medium mr-4 ring-emerald-600 ring-2 text-gray-500 py-0 px-1">6</span>
            </div>
            <!--  chart -->
            <div class="ml-8 pb-8 mt-4">
              <div
                class="ring-1 py-2 text-left cursor-pointer ring-gray-200 shadow-md mr-10 pl-1 chart-height"
              >
                <div class="px-4 py-2 space-y-2.5">
                    <!-- the lodging details -->
                    <div class="py-6 grid grid-flow-row grid-cols-1 grid-rows-1 gap-x-4 gap-y-2 sm:gap-y-1.5">
                      <div class="flex space-x-0.5 text-right mb-2 justify-between">
                        <div class="my-auto">
                          <button @click="switchBudget(1)" :class="[ currentBudgetView == 1 ? 'shadow inline-block box-shadow text-size-sm px-20 py-2 bg-emerald-700  text-white font-medium leading-tight transform focus:bg-emerald-700 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out' : 'shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out']">MI&E Advance</button>
                          <button v-if="showLodgingAdvance" @click="switchBudget(2)" :class="[ currentBudgetView == 2 ? 'shadow inline-block box-shadow text-size-sm px-20 py-2 bg-emerald-700  text-white font-medium leading-tight transform focus:bg-emerald-700 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out' : 'shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out']">Lodging Advance</button>
                          <button @click="switchBudget(3)" :class="[ currentBudgetView == 3 ? 'shadow inline-block box-shadow text-size-sm px-20 py-2 bg-emerald-700  text-white font-medium leading-tight transform focus:bg-emerald-700 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out' : 'shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out']">Other Advance</button>
                        </div>
                        <div>
                          <button @click="clearData()" class="shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-red-600 focus:outline-none focus:ring-0 active:bg-red-800 transition duration-150 ease-in-out">Delete All</button>
                          <button @click="openModal()" class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">Add Details</button>
                        </div>
                      </div>
                      <!-- mie advance -->
                      <div v-if="currentBudgetView===1 && currency !== null && currency.id === 1" class=" overflow-x-auto">
                        <table id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                            <thead>
                              <tr class="bg-emerald-600 pointer-events-none text-size-xs" style="">
                                <td class="py-3 px-2 text-left text-white font-bold">Location</td>
                                <td class="py-3 px-2 text-left text-white font-bold">Description</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Date</td>
                                <!-- <td class="py-3 px-2 border-l text-left text-white font-bold">End Date</td> -->
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Breakfast</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Lunch</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Dinner</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Incidental</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold"># of Days</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">MIE Total (100%)</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Advance (75%)</td>
                                <td class="py-3 px-2 border-l text-center text-white font-bold">Action</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(data, i) in mieData" :key="i" class=" text-gray-600 text-size-sm" style="">
                                <td class="py-3 px-2 border text-left">{{ data.location.name }}</td>
                                <td class="py-3 px-2 border text-left">{{ data.description }}</td>
                                <td class="py-3 px-2 border text-left">{{ formatData(data.startDate) }}</td>
                                <!-- <td class="py-3 px-2 border text-left">{{ formatData(data.endDate) }}</td> -->
                                <td class="py-3 px-2 border text-left">{{ data.breakfast.name }}</td>
                                <td class="py-3 px-2 border text-left">{{ data.lunch.name }}</td>
                                <td class="py-3 px-2 border text-left">{{ data.dinner.name }}</td>
                                <td class="py-3 px-2 border text-left">{{ data.incidental.name }}</td>
                                <td v-if="data.days !== 'Total Cost'" class="py-3 px-2 border text-left">{{ data.days }}</td>
                                <td v-else class="py-3 px-2 border text-left font-medium">{{ data.days }}</td>
                                <td v-if="data.days !== 'Total Cost'" class="py-3 px-2 border text-left">{{ formatData(data.total) }}</td>
                                <td v-else class="py-3 px-2 border text-left font-medium">{{ formatData(data.total) }}</td>
                                <td v-if="data.days !== 'Total Cost'" class="py-3 px-2 border text-left">{{ formatData(data.advance) }}</td>
                                <td v-else class="py-3 px-2 border text-left font-medium">{{ formatData(data.advance) }}</td>
                                <td v-if="data.days !== 'Total Cost'" class="py-3 px-2 border text-center">
                                  <div class="flex mx-auto space-x-4">
                                    <svg @click="deleteItem(i)" class="h-4 w-4 text-red-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.707,15.293-1.414,1.414L12,13.414,8.707,16.707,7.293,15.293,10.586,12,7.293,8.707,8.707,7.293,12,10.586l3.293-3.293,1.414,1.414L13.414,12Z"/></svg>
                                    <svg @click="loadEditMieModal(i)" class="h-4 w-4 text-emerald-600 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M23,24H5c-.55,0-1-.45-1-1s.45-1,1-1H23c.55,0,1,.45,1,1s-.45,1-1,1ZM2.48,11.89c-.31,.73-.48,1.53-.48,2.34v4.35l-1.71,1.71c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29l1.71-1.71H7.76c.81,0,1.61-.17,2.34-.48L2.48,11.89ZM20.91,7.83l-8.67,10.15c-.13,.15-.27,.29-.42,.42L3.59,10.17c.13-.14,.27-.28,.42-.41L14.17,1.1c1.78-1.54,4.47-1.45,6.14,.21l.39,.39c1.66,1.66,1.76,4.36,.22,6.13Z"/></svg>
                                  </div>
                                </td>
                                <td v-else></td>
                              </tr>
                            </tbody>
                        </table>
                      </div>
                       <!-- the international mie -->
                       <div v-else-if="currentBudgetView===1 && currency !== null && currency.id === 2" class=" overflow-x-auto">
                        <!-- {{ mieIntlData }} -->
                        <table id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                            <thead>
                              <tr class="bg-emerald-600 pointer-events-none text-size-xs" style="">
                                <td class="py-3 px-2 text-left text-white font-bold">Location</td>
                                <td class="py-3 px-2 text-left text-white font-bold">Description</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Date</td>
                                <!-- <td class="py-3 px-2 border-l text-left text-white font-bold">End Date</td> -->
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Breakfast</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Lunch</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Dinner</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Incidental</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold"># of Days</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">MIE Total (100%)</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Advance (75%)</td>
                                <td class="py-3 px-2 border-l text-center text-white font-bold">Action</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(data, i) in mieIntlData" :key="i" class=" text-gray-600 text-size-sm" style="">
                                <td class="py-3 px-2 border text-left">{{ data.location.name }}</td>
                                <td class="py-3 px-2 border text-left">{{ data.description }}</td>
                                <td class="py-3 px-2 border text-left">{{ formatData(data.startDate) }}</td>
                                <!-- <td class="py-3 px-2 border text-left">{{ formatData(data.endDate) }}</td> -->
                                <td class="py-3 px-2 border text-left">{{ data.breakfast }}</td>
                                <td class="py-3 px-2 border text-left">{{ data.lunch }}</td>
                                <td class="py-3 px-2 border text-left">{{ data.dinner }}</td>
                                <td class="py-3 px-2 border text-left">{{ data.incidental }}</td>
                                <td v-if="data.days !== 'Total Cost'" class="py-3 px-2 border text-left">{{ data.days }}</td>
                                <td v-else class="py-3 px-2 border text-left font-medium">{{ data.days }}</td>
                                <td v-if="data.days !== 'Total Cost'" class="py-3 px-2 border text-left">{{ formatData(data.total) }}</td>
                                <td v-else class="py-3 px-2 border text-left font-medium">{{ formatData(data.total) }}</td>
                                <td v-if="data.days !== 'Total Cost'" class="py-3 px-2 border text-left">{{ formatData(data.advance) }}</td>
                                <td v-else class="py-3 px-2 border text-left font-medium">{{ formatData(data.advance) }}</td>
                                <td v-if="data.days !== 'Total Cost'" class="py-3 px-2 border text-center">
                                  <div class="flex mx-auto space-x-4">
                                    <svg @click="deleteMieIntlItem(i)" class="h-4 w-4 text-red-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.707,15.293-1.414,1.414L12,13.414,8.707,16.707,7.293,15.293,10.586,12,7.293,8.707,8.707,7.293,12,10.586l3.293-3.293,1.414,1.414L13.414,12Z"/></svg>
                                    <svg @click="loadEditMieIntlModal(i)" class="h-4 w-4 text-emerald-600 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M23,24H5c-.55,0-1-.45-1-1s.45-1,1-1H23c.55,0,1,.45,1,1s-.45,1-1,1ZM2.48,11.89c-.31,.73-.48,1.53-.48,2.34v4.35l-1.71,1.71c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29l1.71-1.71H7.76c.81,0,1.61-.17,2.34-.48L2.48,11.89ZM20.91,7.83l-8.67,10.15c-.13,.15-.27,.29-.42,.42L3.59,10.17c.13-.14,.27-.28,.42-.41L14.17,1.1c1.78-1.54,4.47-1.45,6.14,.21l.39,.39c1.66,1.66,1.76,4.36,.22,6.13Z"/></svg>
                                  </div>
                                </td>
                                <td v-else></td>
                              </tr>
                            </tbody>
                        </table>
                      </div>
                      <!-- lodging advance -->
                      <div class=" overflow-x-auto">
                        <table v-if="currentBudgetView===2" id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                            <thead>
                              <tr class="bg-emerald-600 pointer-events-none text-size-xs" style="">
                                <td class="py-3 px-2 text-left text-white font-bold">Description</td>
                                <td class="py-3 px-2 text-left text-white font-bold">Rate</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold"># of Days</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Total (100%)</td>
                                <td class="py-3 px-2 border-l text-center text-white font-bold">Action</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(data, i) in lodgeData" :key="i" class=" text-gray-600 text-size-sm" style="">
                                <td class="py-3 px-2 border text-left">{{ data.description }}</td>
                                <td class="py-3 px-2 border text-left">{{ formatData(data.rate) }}</td>
                                <td v-if="data.noDays !== 'Total Cost'" class="py-3 px-2 border text-left">{{ data.noDays }}</td>
                                <td v-else class="py-3 px-2 border text-left font-medium">{{ data.noDays }}</td>
                                <td v-if="data.noDays !== 'Total Cost'" class="py-3 px-2 border text-left">{{ formatData(data.total) }}</td>
                                <td v-else class="py-3 px-2 border text-left font-medium">{{ formatData(data.total) }}</td>
                                <td v-if="data.noDays !== 'Total Cost'"  class="py-3 px-2 border text-center flex">
                                  <div class="flex mx-auto space-x-4">
                                    <svg @click="deleteLodgeItem(i)" class="h-4 w-4 text-red-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.707,15.293-1.414,1.414L12,13.414,8.707,16.707,7.293,15.293,10.586,12,7.293,8.707,8.707,7.293,12,10.586l3.293-3.293,1.414,1.414L13.414,12Z"/></svg>
                                    <svg @click="loadEditLodgeModal(i)" class="h-4 w-4 text-emerald-600 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M23,24H5c-.55,0-1-.45-1-1s.45-1,1-1H23c.55,0,1,.45,1,1s-.45,1-1,1ZM2.48,11.89c-.31,.73-.48,1.53-.48,2.34v4.35l-1.71,1.71c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29l1.71-1.71H7.76c.81,0,1.61-.17,2.34-.48L2.48,11.89ZM20.91,7.83l-8.67,10.15c-.13,.15-.27,.29-.42,.42L3.59,10.17c.13-.14,.27-.28,.42-.41L14.17,1.1c1.78-1.54,4.47-1.45,6.14,.21l.39,.39c1.66,1.66,1.76,4.36,.22,6.13Z"/></svg>
                                  </div>
                                </td>
                                <td v-else></td>
                              </tr>
                            </tbody>
                        </table>
                      </div>
                      <!-- other advance -->
                      <div class=" overflow-x-auto">
                       <table v-if="currentBudgetView===3" id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                          <thead>
                            <tr class="bg-emerald-600 pointer-events-none text-size-xs" style="">
                              <td class="py-3 px-2 text-left text-white font-bold">Item</td>
                              <td class="py-3 px-2 text-left text-white font-bold">Quantity</td>
                              <td class="py-3 px-2 border-l text-left text-white font-bold">Price</td>
                              <td class="py-3 px-2 border-l text-left text-white font-bold">Total</td>
                              <td class="py-3 px-2 border-l text-center text-white font-bold">Action</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(data, i) in otherData" :key="i" class=" text-gray-600 text-size-sm" style="">
                              <td class="py-3 px-2 border text-left">{{ data.item }}</td>
                              <td class="py-3 px-2 border text-left">{{ formatData(data.quantity) }}</td>
                              <td v-if="data.price !== 'Total Cost'" class="py-3 px-2 border text-left">{{ data.price }}</td>
                              <td v-else class="py-3 px-2 border text-left font-medium">{{ data.price }}</td>
                              <td v-if="data.price !== 'Total Cost'" class="py-3 px-2 border text-left">{{ formatData(data.total) }}</td>
                              <td v-else class="py-3 px-2 border text-left font-medium">{{ formatData(data.total) }}</td>
                              <td v-if="data.price !== 'Total Cost'"  class="py-3 px-2 border text-center flex">
                                <div class="flex mx-auto space-x-4">
                                  <svg @click="deleteOtherItem(i)" class="h-4 w-4 text-red-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.707,15.293-1.414,1.414L12,13.414,8.707,16.707,7.293,15.293,10.586,12,7.293,8.707,8.707,7.293,12,10.586l3.293-3.293,1.414,1.414L13.414,12Z"/></svg>
                                  <svg @click="loadEditOtherModal(i)" class="h-4 w-4 text-emerald-600 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M23,24H5c-.55,0-1-.45-1-1s.45-1,1-1H23c.55,0,1,.45,1,1s-.45,1-1,1ZM2.48,11.89c-.31,.73-.48,1.53-.48,2.34v4.35l-1.71,1.71c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29l1.71-1.71H7.76c.81,0,1.61-.17,2.34-.48L2.48,11.89ZM20.91,7.83l-8.67,10.15c-.13,.15-.27,.29-.42,.42L3.59,10.17c.13-.14,.27-.28,.42-.41L14.17,1.1c1.78-1.54,4.47-1.45,6.14,.21l.39,.39c1.66,1.66,1.76,4.36,.22,6.13Z"/></svg>
                                </div>
                              </td>
                              <td v-else></td>
                            </tr>
                          </tbody>
                      </table>
                      </div>
                    </div>
                <div class="w-full flex text-center mx-auto pt-1">
                    <div class="flex-1 mb-2">
                      <button
                        @click="back"
                        class="shadow inline-block box-shadow text-size-sm px-16 py-2 bg-emerald-500  text-white font-medium leading-tight transform focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                          Back
                      </button>
                      <button
                        @click="next"
                        class="shadow inline-block box-shadow text-size-sm px-16 py-2 bg-emerald-500  text-white font-medium leading-tight transform focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                          Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- add mie intl modal -->
    <TransitionRoot appear :show="isMieIntlAddOpen" @close="closeMieIntlAddModal()" as="template">
      <Dialog as="div">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 bg-black opacity-80" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="inline-block border w-full max-w-5xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
              >
                <DialogTitle
                  as="h4"
                  class="text-size font-medium leading-6 text-gray-600"
                >
                Add MI&E Advance Details
                </DialogTitle>
                <div class="grid grid-flow-row grid-cols-2 grid-rows-2 gap-4 py-4">
                  <!-- location -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Location <span class="font-bold text-red-500">*</span></label>
                    <div class=" w-full">
                      <CustomDropDown
                        @selectedItems="(data) => { selectedLocation = data}"
                        :elementId="'location'"
                        :url="'/location/searchIntl'"
                        :persistedSelection="selectedLocation"
                        :multipleSelection="false"
                        :display="{
                          id: 'id',
                          name: ['name', 'type']
                        }"
                      />
                    </div>
                  </div>
                  <!-- description -->
                  <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Description <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="description"
                          placeholder="Description"
                          required
                          type="text"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                  <!-- start date -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Date <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="startDate"
                        placeholder="Description"
                        required
                        type="date"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                  <!-- breakfast rate -->
                  <!-- <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Breakfast <span class="font-bold text-red-500">*</span></label>
                    <div class=" w-full">
                      <CustomDropDown
                        @selectedItems="(data) => { selectedBreakfast = data}"
                        :elementId="'breakfast'"
                        :url="'/egpaf-per-diem/searchBreakfast'"
                        :persistedSelection="selectedBreakfast"
                        :multipleSelection="false"
                        :display="{
                          id: 'id',
                          name: ['rate']
                        }"
                      />
                    </div>
                  </div> -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Breakfast <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="intlBreakfast"
                        placeholder="Breakfast"
                        required
                        type="number"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                  <!-- lunch rate -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Lunch <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="intlLunch"
                        placeholder="Lunch"
                        required
                        type="number"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                  <!-- supper rate -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Dinner <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="intlDinner"
                        placeholder="Dinner"
                        required
                        type="number"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                  <!-- incedental rate -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Incidental <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="intlIncidental"
                        placeholder="Incidental"
                        required
                        type="number"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                </div>
                <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                  <button
                    @click="closeMieIntlAddModal()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Cancel
                  </button>
                  <button
                    @click="addMieIntlData()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Save
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- edit mie intl modal -->
    <TransitionRoot appear :show="isMieIntlEditOpen" @close="closeMieIntlEditModal()" as="template">
      <Dialog as="div">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 bg-black opacity-80" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="inline-block border w-full max-w-5xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
              >
                <DialogTitle
                  as="h4"
                  class="text-size font-medium leading-6 text-gray-600"
                >
                Add MI&E Advance Details
                </DialogTitle>
                <div class="grid grid-flow-row grid-cols-2 grid-rows-2 gap-4 py-4">
                  <!-- location -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Location <span class="font-bold text-red-500">*</span></label>
                    <div class=" w-full">
                      <CustomDropDown
                        @selectedItems="(data) => { selectedLocation = data}"
                        :elementId="'location'"
                        :url="'/location/searchIntl'"
                        :persistedSelection="selectedLocation"
                        :multipleSelection="false"
                        :display="{
                          id: 'id',
                          name: ['name', 'type']
                        }"
                      />
                    </div>
                  </div>
                  <!-- description -->
                  <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Description <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="description"
                          placeholder="Description"
                          required
                          type="text"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                  <!-- start date -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Date <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="startDate"
                        placeholder="Description"
                        required
                        type="date"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                  <!-- breakfast rate -->
                  <!-- <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Breakfast <span class="font-bold text-red-500">*</span></label>
                    <div class=" w-full">
                      <CustomDropDown
                        @selectedItems="(data) => { selectedBreakfast = data}"
                        :elementId="'breakfast'"
                        :url="'/egpaf-per-diem/searchBreakfast'"
                        :persistedSelection="selectedBreakfast"
                        :multipleSelection="false"
                        :display="{
                          id: 'id',
                          name: ['rate']
                        }"
                      />
                    </div>
                  </div> -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Breakfast <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="intlBreakfast"
                        placeholder="Breakfast"
                        required
                        type="number"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                  <!-- lunch rate -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Lunch <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="intlLunch"
                        placeholder="Lunch"
                        required
                        type="number"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                  <!-- supper rate -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Dinner <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="intlDinner"
                        placeholder="Dinner"
                        required
                        type="number"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                  <!-- incedental rate -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Incidental <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="intlIncidental"
                        placeholder="Incidental"
                        required
                        type="number"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                </div>
                <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                  <button
                    @click="closeMieIntlEditModal()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Cancel
                  </button>
                  <button
                    @click="editMieIntlData()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Save
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- add mie modal -->
    <TransitionRoot appear :show="isMieAddOpen" @close="closeMieAddModal()" as="template">
      <Dialog as="div">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 bg-black opacity-80" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="inline-block border w-full max-w-5xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
              >
                <DialogTitle
                  as="h4"
                  class="text-size font-medium leading-6 text-gray-600"
                >
                Add MI&E Advance Details
                </DialogTitle>
                <div class="grid grid-flow-row grid-cols-2 grid-rows-2 gap-4 py-4">
                  <!-- location -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Location <span class="font-bold text-red-500">*</span></label>
                    <div class=" w-full">
                      <CustomDropDown
                        @selectedItems="(data) => { selectedLocation = data}"
                        :elementId="'location'"
                        :url="'/location/search'"
                        :persistedSelection="selectedLocation"
                        :multipleSelection="false"
                        :display="{
                          id: 'id',
                          name: ['name', 'type']
                        }"
                      />
                    </div>
                  </div>
                  <!-- description -->
                  <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Description <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="description"
                          placeholder="Description"
                          required
                          type="text"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                  <!-- start date -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Date <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="startDate"
                        placeholder="Description"
                        required
                        type="date"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                  <!-- end date -->
                  <!-- <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">End Date <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="endDate"
                        placeholder="Description"
                        required
                        type="date"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div> -->
                  <!-- breakfast rate -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Breakfast <span class="font-bold text-red-500">*</span></label>
                    <div class=" w-full">
                      <CustomDropDown
                        @selectedItems="(data) => { selectedBreakfast = data}"
                        :elementId="'breakfast'"
                        :url="'/egpaf-per-diem/searchBreakfast'"
                        :persistedSelection="selectedBreakfast"
                        :multipleSelection="false"
                        :display="{
                          id: 'id',
                          name: ['rate']
                        }"
                      />
                    </div>
                  </div>
                  <!-- lunch rate -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Lunch <span class="font-bold text-red-500">*</span></label>
                    <div class=" w-full">
                      <CustomDropDown
                        @selectedItems="(data) => { selectedLunch = data}"
                        :elementId="'lunch'"
                        :url="'/egpaf-per-diem/searchLunch'"
                        :persistedSelection="selectedLunch"
                        :multipleSelection="false"
                        :display="{
                          id: 'id',
                          name: ['rate']
                        }"
                      />
                    </div>
                  </div>
                  <!-- supper rate -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Dinner <span class="font-bold text-red-500">*</span></label>
                    <div class=" w-full">
                      <CustomDropDown
                        @selectedItems="(data) => { selectedDinner = data}"
                        :elementId="'supper'"
                        :url="'/egpaf-per-diem/searchDinner'"
                        :persistedSelection="selectedDinner"
                        :multipleSelection="false"
                        :display="{
                          id: 'id',
                          name: ['rate']
                        }"
                      />
                    </div>
                  </div>
                  <!-- breakfast rate -->
                  <div class="col-span-full sm:col-span-1">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Incidental <span class="font-bold text-red-500">*</span></label>
                    <div class=" w-full">
                      <CustomDropDown
                        @selectedItems="(data) => { selectedIncidental = data}"
                        :elementId="'incidental'"
                        :url="'/egpaf-per-diem/searchIncidental'"
                        :persistedSelection="selectedIncidental"
                        :multipleSelection="false"
                        :display="{
                          id: 'id',
                          name: ['rate']
                        }"
                      />
                    </div>
                  </div>
                </div>
                <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                  <button
                    @click="closeMieAddModal()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Cancel
                  </button>
                  <button
                    @click="addMieData()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Save
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- edit mie modal -->
    <TransitionRoot appear :show="isMieEditOpen" @close="closeMieEditModal()" as="template">
      <Dialog as="div">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 bg-black opacity-80" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="inline-block border w-full max-w-5xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
              >
                <DialogTitle
                  as="h4"
                  class="text-size font-medium leading-6 text-gray-600"
                >
                Edit MI&E Advance
                </DialogTitle>
                <div class="grid grid-flow-row grid-cols-2 grid-rows-2 gap-4 py-4">
                  <!-- location -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Location <span class="font-bold text-red-500">*</span></label>
                    <div class=" w-full">
                      <CustomDropDown
                        @selectedItems="(data) => { selectedLocation = data}"
                        :elementId="'location'"
                        :url="'/location/search'"
                        :persistedSelection="selectedLocation"
                        :multipleSelection="false"
                        :display="{
                          id: 'id',
                          name: ['name', 'type']
                        }"
                      />
                    </div>
                  </div>
                  <!-- description -->
                  <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Description <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="description"
                          placeholder="Description"
                          required
                          type="text"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                  <!-- start date -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Date <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="startDate"
                        placeholder="Description"
                        required
                        type="date"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                  <!-- end date -->
                  <!-- <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">End Date <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="endDate"
                        placeholder="Description"
                        required
                        type="date"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div> -->
                  <!-- breakfast rate -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Breakfast <span class="font-bold text-red-500">*</span></label>
                    <div class=" w-full">
                      <CustomDropDown
                        @selectedItems="(data) => { selectedBreakfast = data}"
                        :elementId="'breakfast'"
                        :url="'/egpaf-per-diem/searchBreakfast'"
                        :persistedSelection="selectedBreakfast"
                        :multipleSelection="false"
                        :display="{
                          id: 'id',
                          name: ['rate']
                        }"
                      />
                    </div>
                  </div>
                  <!-- lunch rate -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Lunch <span class="font-bold text-red-500">*</span></label>
                    <div class=" w-full">
                      <CustomDropDown
                        @selectedItems="(data) => { selectedLunch = data}"
                        :elementId="'lunch'"
                        :url="'/egpaf-per-diem/searchLunch'"
                        :persistedSelection="selectedLunch"
                        :multipleSelection="false"
                        :display="{
                          id: 'id',
                          name: ['rate']
                        }"
                      />
                    </div>
                  </div>
                  <!-- supper rate -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Dinner <span class="font-bold text-red-500">*</span></label>
                    <div class=" w-full">
                      <CustomDropDown
                        @selectedItems="(data) => { selectedDinner = data}"
                        :elementId="'supper'"
                        :url="'/egpaf-per-diem/searchDinner'"
                        :persistedSelection="selectedDinner"
                        :multipleSelection="false"
                        :display="{
                          id: 'id',
                          name: ['rate']
                        }"
                      />
                    </div>
                  </div>
                  <!-- breakfast rate -->
                  <div class="col-span-full sm:col-span-1">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Incidental <span class="font-bold text-red-500">*</span></label>
                    <div class=" w-full">
                      <CustomDropDown
                        @selectedItems="(data) => { selectedIncidental = data}"
                        :elementId="'incidental'"
                        :url="'/egpaf-per-diem/searchIncidental'"
                        :persistedSelection="selectedIncidental"
                        :multipleSelection="false"
                        :display="{
                          id: 'id',
                          name: ['rate']
                        }"
                      />
                    </div>
                  </div>
                </div>
                <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                  <button
                    @click="closeMieEditModal()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Cancel
                  </button>
                  <button
                    @click="editMieData()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Save
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- add lodge modal -->
    <TransitionRoot appear :show="isLodgeAddOpen" @close="closeLodgeAddModal()" as="template">
      <Dialog as="div">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 bg-black opacity-80" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="inline-block border w-full max-w-5xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
              >
                <DialogTitle
                  as="h4"
                  class="text-size font-medium leading-6 text-gray-600"
                >
                Add Lodging Advance
                </DialogTitle>
                <div class="grid grid-flow-row grid-cols-2 gap-4 py-4">
                  <!-- description -->
                   <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Description <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="description"
                        placeholder="Lodging Description"
                        required
                        type="text"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                  <!-- rate -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Rate <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="rate"
                        placeholder="Lodging Rate"
                        required
                        type="number"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                  <!-- no of days -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2"># of Days <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="noDays"
                        placeholder="Number of Days."
                        required
                        type="number"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                </div>
                <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                  <button
                    @click="closeLodgeAddModal()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Cancel
                  </button>
                  <button
                    @click="addLodgeData()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Save
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- edit lodge modal -->
    <TransitionRoot appear :show="isLodgeEditOpen" @close="closeLodgeEditModal()" as="template">
      <Dialog as="div">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 bg-black opacity-80" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="inline-block border w-full max-w-5xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
              >
                <DialogTitle
                  as="h4"
                  class="text-size font-medium leading-6 text-gray-600"
                >
                Edit Lodging Advance
                </DialogTitle>
                <div class="grid grid-flow-row grid-cols-2 gap-4 py-4">
                  <!-- description -->
                   <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Description <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="description"
                        placeholder="Lodging Description"
                        required
                        type="text"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                  <!-- rate -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Rate <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="rate"
                        placeholder="Lodging Rate"
                        required
                        type="number"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                  <!-- no of days -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2"># of Days <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="noDays"
                        placeholder="Number of Days."
                        required
                        type="number"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                </div>
                <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                  <button
                    @click="closeLodgeEditModal()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Cancel
                  </button>
                  <button
                    @click="editLodgeData()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Save
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- add other modal -->
    <TransitionRoot appear :show="isOtherAddOpen" @close="closeOtherAddModal()" as="template">
      <Dialog as="div">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 bg-black opacity-80" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="inline-block border w-full max-w-5xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
              >
                <DialogTitle
                  as="h4"
                  class="text-size font-medium leading-6 text-gray-600"
                >
                Add Other Advance
                </DialogTitle>
                <div class="grid grid-flow-row grid-cols-2 gap-4 py-4">
                  <!-- item -->
                   <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Item <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="item"
                        placeholder="Item"
                        required
                        type="text"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                  <!-- quantity -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Quantity <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="quantity"
                        placeholder="Quantity"
                        required
                        type="number"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                  <!-- price -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Price <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="price"
                        placeholder="Price"
                        required
                        type="number"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                </div>
                <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                  <button
                    @click="closeOtherAddModal()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Cancel
                  </button>
                  <button
                    @click="addOtherData()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Save
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- edit other modal -->
    <TransitionRoot appear :show="isOtherEditOpen" @close="closeOtherEditModal()" as="template">
      <Dialog as="div">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 bg-black opacity-80" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="inline-block border w-full max-w-5xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
              >
                <DialogTitle
                  as="h4"
                  class="text-size font-medium leading-6 text-gray-600"
                >
                Add Other Advance
                </DialogTitle>
                <div class="grid grid-flow-row grid-cols-2 gap-4 py-4">
                  <!-- item -->
                   <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Item <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="item"
                        placeholder="Item"
                        required
                        type="text"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                  <!-- quantity -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Quantity <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="quantity"
                        placeholder="Quantity"
                        required
                        type="number"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                  <!-- price -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Price <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="price"
                        placeholder="Price"
                        required
                        type="number"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                </div>
                <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                  <button
                    @click="closeOtherEditModal()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Cancel
                  </button>
                  <button
                    @click="editOtherData()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Save
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- the loader modal -->
    <Loader ref="loaderRef"/>
    <!-- the message loader -->
    <Message :title="title" :message="processMessage" ref="messageRef"/>
  </div>
</template>

<script>
import Navbar from '@/components/EmployeeNavbar.vue'
import Sidebar from '@/components/EmployeeSideBar.vue'
import { mapState } from 'vuex'
import { ajax } from 'rxjs/ajax'
 import { ref } from 'vue'
import Message from './modals/Message.vue';
import Loader from './modals/Loader.vue';
import CustomDropDown from './CustomDropDown.vue';
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue'

export default {
  computed: {
    ...mapState([
      'employee',
      'advance',
      'mieAdvance',
      'mieIntlAdvance',
      'lodgingAdvance',
      'otherAdvance'
    ])
  },
  components: {
    Navbar,
    Sidebar,
    Loader,
    Message,
    CustomDropDown,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay
  },
  setup () {
    const isMieAddOpen = ref(false)
    const isMieIntlAddOpen = ref(false)
    const isMieEditOpen = ref(false)
    const isMieIntlEditOpen = ref(false)
    const isLodgeAddOpen = ref(false)
    const isLodgeEditOpen = ref(false)
    const isOtherAddOpen = ref(false)
    const isOtherEditOpen = ref(false)
    return {
      isMieAddOpen,
      isMieIntlAddOpen,
      isMieEditOpen,
      isMieIntlEditOpen,
      isLodgeAddOpen,
      isLodgeEditOpen,
      isOtherAddOpen,
      isOtherEditOpen,
      closeMieAddModal () {
        isMieAddOpen.value = false
      },
      openMieAddModal () {
        isMieAddOpen.value = true
      },
      closeMieIntlAddModal () {
        isMieIntlAddOpen.value = false
      },
      openMieIntlAddModal () {
        isMieIntlAddOpen.value = true
      },
      closeMieEditModal () {
        isMieEditOpen.value = false
      },
      openMieEditModal () {
        isMieEditOpen.value = true
      },
      closeMieIntlEditModal () {
        isMieIntlEditOpen.value = false
      },
      openMieIntlEditModal () {
        isMieIntlEditOpen.value = true
      },
      closeLodgeAddModal () {
        isLodgeAddOpen.value = false
      },
      openLodgeAddModal () {
        isLodgeAddOpen.value = true
      },
      closeLodgeEditModal () {
        isLodgeEditOpen.value = false
      },
      openLodgeEditModal () {
        isLodgeEditOpen.value = true
      },
      closeOtherAddModal () {
        isOtherAddOpen.value = false
      },
      openOtherAddModal () {
        isOtherAddOpen.value = true
      },

      closeOtherEditModal () {
        isOtherEditOpen.value = false
      },
      openOtherEditModal () {
        isOtherEditOpen.value = true
      }
    }
  },
  name: 'create-request-3',
  props: {
  },
  data: () => {
    return {
      showLodgingAdvance: false,
      title: 'Error Message',
      processMessage: '',
      showMieIntl: false,
      showMie: false,
      selectedLocation: {},
      startDate: '',
      endDate: '',
      selectedBreakfast: {},
      selectedLunch: {},
      selectedDinner: {},
      selectedIncidental: {},
      intlBreakfast: 0,
      intlLunch: 0,
      intlDinner: 0,
      intlIncidental: 0,
      mieData: [],
      mieIntlData: [],
      description: '',
      rate: 0,
      noDays: 0,
      lodgeData: [],
      showLodge: false,
      otherData: [],
      item: '',
      quantity: 1,
      price: 0,
      currency: null,
      selectedEditIndex: null,
      currentBudgetView: 1,
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    }
  },
  mounted () {
    // removing the submenu items selection
    if (this.employee !== null) {
      this.$store.commit('mutateActiveListItemIndex', 400)
      this.$store.commit('mutateSelectedSubMenuItem', 400)
      // load the stored vuex data
      this.loadMieVuex()
      this.loadMieIntlVuex()
      this.loadLodgingVuex()
      this.loadOtherVuex()
      // show the advance
      if (this.advance !== null && this.advance.needAdvanceLodging.name !== undefined && this.advance.needAdvanceLodging.name === 'Yes') {
        this.showLodgingAdvance = true
      }
    }
    if (this.advance !== null && this.advance.currency !== null) {
      this.currency = this.advance.currency.allData
    }
  },
  methods: {
    // compare rows
    compareRows (dataArray, rowData) {
      if (Array.isArray(dataArray)) {
        const size = dataArray.length
        const dataString = JSON.stringify(rowData)

        for (let i = 0; i < size; i++) {
          const dataRowItem = dataArray[i]
          const JSONDataString = JSON.stringify(dataRowItem)

          if (dataString === JSONDataString) {
            return true
          }
        }

        return false
      } else {
        return false
      }
    },
    editLodgeData () {
      // check if the details are empty
      if (this.description !== undefined && !isNaN(this.rate) && !isNaN(this.noDays) && this.rate > 0 && this.noDays > 0 ) {
        // remove the grand total
        this.removeLodgeGrandTotal()
        // hide the mie
        this.showLodge = false
        // calculate the total for all the calculations
        const total = parseFloat(this.rate) * parseInt(this.noDays)
        // adding the data
        const rowData = {
          description: this.description,
          rate: this.rate,
          noDays: this.noDays,
          total: total
        }
        // add it in the mie
        this.lodgeData[this.selectedEditIndex] = rowData

        // calculate the grand total
        this.calculateLodgeGrandTotal()

        //  close the add modal
        this.closeLodgeEditModal()
      }
    },
    addLodgeData () {
      // check if the details are empty
      if (this.description !== '' && !isNaN(this.rate) && !isNaN(this.noDays) && this.rate > 0 && this.noDays > 0 ) {
        // remove the grand total
        this.removeLodgeGrandTotal()
        // hide the mie
        this.showLodge = false
        // calculate the total for all the calculations
        const total = parseFloat(this.rate) * parseInt(this.noDays)
        // adding the data
        const rowData = {
          description: this.description,
          rate: this.rate,
          noDays: this.noDays,
          total: total
        }
        // add it in the mie
        this.lodgeData.push(rowData)

        // calculate the grand total
        this.calculateLodgeGrandTotal()

        //  close the add modal
        this.closeLodgeAddModal()
      }
    },
    addOtherData () {
      // check if the details are empty
      if (this.item !== '' && !isNaN(this.quantity) && !isNaN(this.price) && this.quantity > 0 && this.price > 0 ) {
        // remove the grand total
        this.removeOtherGrandTotal()
        // hide the other
        this.showOther = false
        // calculate the total for all the calculations
        const total = parseFloat(this.quantity) * parseInt(this.price)
        // adding the data
        const rowData = {
          item: this.item,
          quantity: this.quantity,
          price: this.price,
          total: total
        }
        // add it in the other
        this.otherData.push(rowData)

        // calculate the grand total
        this.calculateOtherGrandTotal()

        //  close the add modal
        this.closeOtherAddModal()
      }
    },
    editOtherData () {
      // check if the details are empty
      if (this.item !== '' && !isNaN(this.quantity) && !isNaN(this.price) && this.quantity > 0 && this.price > 0 ) {
        // remove the grand total
        this.removeOtherGrandTotal()
        // hide the other
        this.showOther = false
        // calculate the total for all the calculations
        const total = parseFloat(this.quantity) * parseInt(this.price)
        // adding the data
        const rowData = {
          item: this.item,
          quantity: this.quantity,
          price: this.price,
          total: total
        }
        // add it in the other
        this.otherData[this.selectedEditIndex] = rowData

        // calculate the grand total
        this.calculateOtherGrandTotal()

        //  close the add modal
        this.closeOtherEditModal()
      }
    },
    // date difference in days
    dateDiff (startDate, endDate) {
      const date2 = new Date(endDate)
      const date1 = new Date(startDate)

      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      // remove the time and time-zone details.
      const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
      const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

      return (Math.floor((utc2 - utc1) / _MS_PER_DAY) + 1);
    },
    // open the appropriate modal
    openModal () {
      switch(this.currentBudgetView) {
        case 1:
          if (this.currency !== null && this.currency.id === 1) {
            this.openMieAddModal()
          } else if (this.currency !== null) {
            this.openMieIntlAddModal()
          }
          break
        case 2:
          this.openLodgeAddModal()
          break
        default:
          this.openOtherAddModal()
      }
    },
    switchBudget (index) {
      this.currentBudgetView = index
    },
    // load the edit modal
    loadEditMieModal (index) {
      // get the row details
      const rowData = this.mieData[index]

      this.selectedLocation = rowData.location
      this.description = rowData.description
      this.startDate = rowData.startDate
      this.endDate = rowData.endDate
      this.selectedBreakfast = rowData.breakfast
      this.selectedLunch = rowData.lunch
      this.selectedDinner = rowData.dinner
      this.selectedIncidental = rowData.incidental

      // set the index to be edited
      this.selectedEditIndex = index
      this.openMieEditModal()
    },
     // load the edit modal
     loadEditMieIntlModal (index) {
      // get the row details
      const rowData = this.mieIntlData[index]

      this.selectedLocation = rowData.location
      this.description = rowData.description
      this.startDate = rowData.startDate
      this.endDate = rowData.endDate
      this.intlBreakfast = rowData.breakfast
      this.intlLunch = rowData.lunch
      this.intlDinner = rowData.dinner
      this.intlIncidental = rowData.incidental

      // set the index to be edited
      this.selectedEditIndex = index
      this.openMieIntlEditModal()
    },
    // load the edit modal
    loadEditLodgeModal (index) {
      // get the row details
      const rowData = this.lodgeData[index]

      this.description = rowData.description
      this.rate = rowData.rate
      this.noDays = rowData.noDays

      // set the index to be edited
      this.selectedEditIndex = index
      this.openLodgeEditModal()
    },
    // load the edit modal
    loadEditOtherModal (index) {
      // get the row details
      const rowData = this.otherData[index]

      this.item = rowData.item
      this.quantity = rowData.quantity
      this.price = rowData.price

      // set the index to be edited
      this.selectedEditIndex = index
      this.openOtherEditModal()
    },
    // delete an item from the
    deleteItem (index) {
      // remove the selected item
      this.showMie = false
      // remove the grand total
      this.removeGrandTotal()
      // delete the item
      this.mieData.splice(index, 1)
      this.calculateGrandTotal()
    },
      // delete an item from the
      deleteMieIntlItem (index) {
      // remove the selected item
      this.showMieIntl = false
      // remove the grand total
      this.removeMieIntlGrandTotal()
      // delete the item
      this.mieIntlData.splice(index, 1)
      this.calculateMieIntlGrandTotal()
    },
    // delete an item from the
    deleteLodgeItem (index) {
      // remove the selected item
      this.showLodge = false
      // remove the grand total
      this.removeLodgeGrandTotal()
      // delete the item
      this.lodgeData.splice(index, 1)
      this.calculateLodgeGrandTotal()
    }, 
    // delete an item from the
    deleteOtherItem (index) {
      // remove the selected item
      this.showOther = false
      // remove the grand total
      this.removeOtherGrandTotal()
      // delete the item
      this.otherData.splice(index, 1)
      this.calculateOtherGrandTotal()
    }, 
    // load vuex mie data
    loadMieVuex () {
      if (this.mieAdvance !== null) {
        this.showMie = false
        this.mieData = this.mieAdvance
        this.showMie = true
      }
    },
    // load vuex lodging data
    loadLodgingVuex () {
      if (this.lodgingAdvance !== null) {
        this.showLodge = false
        this.lodgeData = this.lodgingAdvance
        this.showLodge = true
      }
    },
     // load vuex mie data
     loadMieIntlVuex () {
      if (this.mieIntlAdvance !== null) {
        this.showMieIntl = false
        this.mieIntlData = this.mieIntlAdvance
        this.showMieIntl = true
      }
    },
    // load vuex other data
    loadOtherVuex () {
      if (this.otherAdvance !== null) {
        this.showOther = false
        this.otherData = this.otherAdvance
        this.showOther = true
      }
    },
    // remove the last row
    removeGrandTotal () {
      // only if the data is > 0
      const size = this.mieData.length
      if (size > 0) {
        this.mieData.splice((size -1), 1)
      }
    },
    // remove the last row
    removeLodgeGrandTotal () {
      // only if the data is > 0
      const size = this.lodgeData.length
      if (size > 0) {
        this.lodgeData.splice((size -1), 1)
      }
    },
     // remove the last row
     removeMieIntlGrandTotal () {
      // only if the data is > 0
      const size = this.mieIntlData.length
      if (size > 0) {
        this.mieIntlData.splice((size -1), 1)
      }
    },
    // remove the last row
    removeOtherGrandTotal () {
      // only if the data is > 0
      const size = this.otherData.length
      if (size > 0) {
        this.otherData.splice((size -1), 1)
      }
    },
    // calulates the grand total
    calculateGrandTotal () {
      // get the data size
      const size = this.mieData.length

      
      if (size > 0) {
        this.mieData.sort((a, b) => {
          if (a.startDate < b.startDate) {
            return -1
          } else if (a.startDate > b.startDate) {
            return 1
          } else {
            return 0
          }
        })

        // loop through the data calculate the 75% of the firstrow and the last row
        for (let i = 0; i < size; i++) {
          const row = this.mieData[i]
          const breakfast = row.breakfast.name
          const lunch = row.lunch.name
          const dinner = row.dinner.name
          const incidental = row.incidental.name

          console.log(row)
          let mieTotal = parseFloat(breakfast) + parseFloat(lunch) + parseFloat(dinner) + parseFloat(incidental)
          let advance = 0
          
          if (i === 0 || i === (size-1)) {
            mieTotal = mieTotal * 0.75
          }
          advance = mieTotal * 0.75

          this.mieData[i].advance = advance
          this.mieData[i].total = mieTotal
        }
         
      }

      // calculate the grand total
      let mieTotal = 0
      let advanceTotal = 0

      for (let i = 0; i < size; i++) {
        const rowData = this.mieData[i]
        const mie = parseFloat(rowData.total)
        const advance = parseFloat(rowData.advance)

        mieTotal += mie
        advanceTotal += advance
      }

      const rowData = {
            location: '',
            description: '',
            startDate: '',
            // endDate: '',
            breakfast: '',
            lunch: '',
            dinner: '',
            incidental: '',
            days: 'Total Cost',
            total: mieTotal,
            advance: advanceTotal
          }
      // add it in the mie
      this.mieData.push(rowData)
      // show the mie
      this.showMie = true
      // save the mie state
      this.$store.commit('mutateMieAdvance', this.mieData)
    },
    // calulates the grand total
    calculateMieIntlGrandTotal () {
      // get the data size
      const size = this.mieIntlData.length

      // sorting the data
      if (size > 0) {
        this.mieIntlData.sort((a, b) => {
          if (a.startDate < b.startDate) {
            return -1
          } else if (a.startDate > b.startDate) {
            return 1
          } else {
            return 0
          }
        })

        // loop through the data calculate the 75% of the firstrow and the last row
        for (let i = 0; i < size; i++) {
          const row = this.mieIntlData[i]
          const breakfast = row.breakfast
          const lunch = row.lunch
          const dinner = row.dinner
          const incidental = row.incidental

          let mieTotal = parseFloat(breakfast) + parseFloat(lunch) + parseFloat(dinner) + parseFloat(incidental)
          let advance = 0
          
          if (i === 0 || i === (size-1)) {
            mieTotal = mieTotal * 0.75
          }
          advance = mieTotal * 0.75

          this.mieIntlData[i].advance = advance
          this.mieIntlData[i].total = mieTotal
        }
      }

      // calculate the grand total
      let mieTotal = 0
      let advanceTotal = 0

      for (let i = 0; i < size; i++) {
        const rowData = this.mieIntlData[i]
        const mie = parseFloat(rowData.total)
        const advance = parseFloat(rowData.advance)

        mieTotal += mie
        advanceTotal += advance
      }
      

      const rowData = {
            location: '',
            description: '',
            startDate: '',
            // endDate: '',
            breakfast: '',
            lunch: '',
            dinner: '',
            incidental: '',
            days: 'Total Cost',
            total: mieTotal,
            advance: advanceTotal
          }
      // add it in the mie
      this.mieIntlData.push(rowData)
      // show the mie
      this.showMieIntl = true
      // save the mie state
      this.$store.commit('mutateMieIntlAdvance', this.mieIntlData)
    },
    // calulates the grand total
    calculateLodgeGrandTotal () {
      // get the data size
      const size = this.lodgeData.length

      // calculate the grand total
      let total = 0

      for (let i = 0; i < size; i++) {
        const rowData = this.lodgeData[i]
        const rowtotal = parseFloat(rowData.total)

        total += rowtotal
      }

      const rowData = {
        description: '',
        rate: '',
        noDays: 'Total Cost',
        total: total
      }
      // add it in the mie
      this.lodgeData.push(rowData)
      // show the mie
      this.showLodge = true
      // save the lodge state
      this.$store.commit('mutateLodgingAdvance', this.lodgeData)
    },
    addMieIntlData () {
        // check if the details are empty
        if (this.selectedLocation.name !== undefined && this.description !== '' && this.startDate !== '' && !isNaN(this.intlBreakfast) && !isNaN(this.intlLunch)
            && !isNaN(this.intlDinner) && !isNaN(this.intlIncidental)
        ) {
          // hide the mie
          this.showMie = false
          // get the # of days
          const days = 1
          // calculate the mie total and advance
          const mieTotal = (parseFloat(this.intlBreakfast) + parseFloat(this.intlLunch) + parseFloat(this.intlDinner) + parseFloat(this.intlIncidental)) * days
          // calculate the advance total
          // console.log(mieTotal, '<<<<<<<<<<<')
          const advanceTotal = mieTotal * 0.75
          // adding the data
          const rowData = {
            location: this.selectedLocation,
            description: this.description,
            startDate: this.startDate,
            // endDate: this.endDate,
            breakfast: this.intlBreakfast,
            lunch: this.intlLunch,
            dinner: this.intlDinner,
            incidental: this.intlIncidental,
            days: days,
            total: mieTotal,
            advance: advanceTotal
          }

          if (!this.compareRows(this.mieIntlData, rowData)) {
            // remove the grand total
            this.removeMieIntlGrandTotal()

            // add it in the mie
            this.mieIntlData.push(rowData)

            // calculate the grand total
            this.calculateMieIntlGrandTotal()
          }

          //  close the add a schedule modal
          this.closeMieIntlAddModal()
        } else {
          // console.log(this.selectedLocation.name !== undefined, this.startDate !== '', this.endDate !== '', this.selectedBreakfast.name, this.selectedLunch !== undefined, this.selectedDinner.name !== undefined, this.selectedIncidental.name !== undefined)
        }
    },
    editMieIntlData () {
        // check if the details are empty
        if (this.selectedLocation.name !== undefined && this.description !== '' && this.startDate !== '' && !isNaN(this.intlBreakfast) && !isNaN(this.intlLunch)
            && !isNaN(this.intlDinner) && !isNaN(this.intlIncidental)
        ) {
          // hide the mie
          this.showMieIntl = false
          // get the # of days
          const days = 1
          // calculate the mie total and advance
          const mieTotal = (parseFloat(this.intlBreakfast) + parseFloat(this.intlLunch) + parseFloat(this.intlDinner) + parseFloat(this.intlIncidental)) * days
          // calculate the advance total
          // console.log(mieTotal, '<<<<<<<<<<<')
          const advanceTotal = mieTotal * 0.75
          // adding the data
          const rowData = {
            location: this.selectedLocation,
            description: this.description,
            startDate: this.startDate,
            // endDate: this.endDate,
            breakfast: this.intlBreakfast,
            lunch: this.intlLunch,
            dinner: this.intlDinner,
            incidental: this.intlIncidental,
            days: days,
            total: mieTotal,
            advance: advanceTotal
          }

          if (!this.compareRows(this.mieIntlData, rowData)) {
            // remove the grand total
            this.removeMieIntlGrandTotal()

            // add it in the mie
            this.mieIntlData[this.selectedEditIndex] = rowData

            // calculate the grand total
            this.calculateMieIntlGrandTotal()
          }

          //  close the add a schedule modal
          this.closeMieIntlEditModal()
        } else {
          // console.log(this.selectedLocation.name !== undefined, this.startDate !== '', this.endDate !== '', this.selectedBreakfast.name, this.selectedLunch !== undefined, this.selectedDinner.name !== undefined, this.selectedIncidental.name !== undefined)
        }
    },
    // calulates the grand total
    calculateOtherGrandTotal () {
      // get the data size
      const size = this.otherData.length

      // calculate the grand total
      let total = 0

      for (let i = 0; i < size; i++) {
        const rowData = this.otherData[i]
        const rowtotal = parseFloat(rowData.total)

        total += rowtotal
      }

      const rowData = {
        item: '',
        quantity: '',
        price: 'Total Cost',
        total: total
      }
      // add it in the other
      this.otherData.push(rowData)
      // show the other
      this.showOther = true
      // save the other state
      this.$store.commit('mutateOtherAdvance', this.otherData)
    },
    // check the start and end date
    isDateGreaterThan(endDate, startDate) {
        // Ensure both dates are Date objects
        const date1 = new Date(endDate);
        const date2 = new Date(startDate);
        if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
          throw new Error("Both arguments must be Date objects.");
        }

        // Compare the timestamps
        return date1.getTime() >= date2.getTime();
    },
    // add mie data
    addMieData () {
        // check if the details are empty
        // if (this.selectedLocation.name !== undefined && this.description !== '' && this.startDate !== '' && this.endDate !== '' && this.selectedBreakfast.name !== undefined && this.selectedLunch !== undefined
        //     && this.selectedDinner.name !== undefined && this.selectedIncidental.name !== undefined && this.isDateGreaterThan(this.endDate, this.startDate)
        // ) 
        if (this.selectedLocation.name !== undefined && this.description !== '' && this.startDate !== '' && this.selectedBreakfast.name !== undefined && this.selectedLunch !== undefined
            && this.selectedDinner.name !== undefined && this.selectedIncidental.name !== undefined
        ) {
          // hide the mie
          this.showMie = false
          // get the # of days
          const days = 1
          // calculate the mie total and advance
          const mieTotal = (parseFloat(this.selectedBreakfast.name) + parseFloat(this.selectedLunch.name) + parseFloat(this.selectedDinner.name) + parseFloat(this.selectedIncidental.name)) * days
          // calculate the advance total
          const advanceTotal = mieTotal * 0.75
          // adding the data
          const rowData = {
            location: this.selectedLocation,
            description: this.description,
            startDate: this.startDate,
            // endDate: this.endDate,
            breakfast: this.selectedBreakfast,
            lunch: this.selectedLunch,
            dinner: this.selectedDinner,
            incidental: this.selectedIncidental,
            days: days,
            total: mieTotal,
            advance: advanceTotal
          }

          if (!this.compareRows(this.mieData, rowData)) {
            // remove the grand total
            this.removeGrandTotal()

            // add it in the mie
            this.mieData.push(rowData)

            // calculate the grand total
            this.calculateGrandTotal()
          }

          //  close the add a schedule modal
          this.closeMieAddModal()
        } else {
          // console.log(this.selectedLocation.name !== undefined, this.startDate !== '', this.endDate !== '', this.selectedBreakfast.name, this.selectedLunch !== undefined, this.selectedDinner.name !== undefined, this.selectedIncidental.name !== undefined)
        }
    },
    // edit mie data
    editMieData () {
        // check if the details are empty
        if (this.selectedLocation.name !== undefined && this.description !== '' && this.startDate !== '' && this.selectedBreakfast.name !== undefined && this.selectedLunch !== undefined
            && this.selectedDinner.name !== undefined && this.selectedIncidental.name !== undefined
        ) {
          // remove the grand total
          this.removeGrandTotal()
          // hide the mie
          this.showMie = false
          // get the # of days
          // const days = this.dateDiff(this.startDate, this.endDate) 
          const days = 1
          // calculate the mie total and advance
          const mieTotal = (parseFloat(this.selectedBreakfast.name) + parseFloat(this.selectedLunch.name) + parseFloat(this.selectedDinner.name) + parseFloat(this.selectedIncidental.name)) * days
          // calculate the advance total
          const advanceTotal = mieTotal * 0.75
          // adding the data
          const rowData = {
            location: this.selectedLocation,
            description: this.description,
            startDate: this.startDate,
            // endDate: this.endDate,
            breakfast: this.selectedBreakfast,
            lunch: this.selectedLunch,
            dinner: this.selectedDinner,
            incidental: this.selectedIncidental,
            days: days,
            total: mieTotal,
            advance: advanceTotal
          }
          
          // add it in the mie
          this.mieData[this.selectedEditIndex] = rowData

          // calculate the grand total
          this.calculateGrandTotal()

          //  close the add a schedule modal
          this.closeMieEditModal()
        } else {
          // console.log(this.selectedLocation.name !== undefined, this.startDate !== '', this.endDate !== '', this.selectedBreakfast.name, this.selectedLunch !== undefined, this.selectedDinner.name !== undefined, this.selectedIncidental.name !== undefined)
        }
    },
    // format the table data
    formatData (data) {
      var formattedData
      // check if it is a number
      if (data !== null && data !== undefined && !isNaN(data) && String(data).length > 2) {
        // format the number to a currency format
        var currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'MWK' })
        formattedData = currencyFormat.format(String(data)).substring(4)
      } else if (data !== null && data !== undefined && data.toString().includes('-') && !isNaN(Date.parse(data))) {
        // check if the data is a date
        // formating the dates supplied
        var textDate = new Date(data)
        var formattedTextDate = textDate.getDate() + ' ' + this.months[textDate.getMonth()] + ' ' + textDate.getFullYear()
        formattedData = formattedTextDate
      } else {
        formattedData = data
      }
      return formattedData
    },
    // clear the mie data
    clearData () {
      switch (this.currentBudgetView) {
        case 1:
        if ( this.currency !== null && this.currency.id === 1) {
            this.mieData = []
            this.$store.commit('mutateMieAdvance', this.mieData)
          } else if (this.currency !== null) {
            this.mieIntlData = []
            this.$store.commit('mutateMieIntlAdvance', this.mieIntlData)
          }
          break
        case 2:
          this.lodgeData = []
          this.$store.commit('mutateLodgingAdvance', this.lodgeData)
          break
        default:
          this.otherData = []
          this.$store.commit('mutateOtherAdvance', this.otherData)
      }
    },
    // move to the previous page
    back () {
      this.$router.push('/edit-request-2')
    },
     // advances cleanup
     cleanAdvances () {
      // clean the mie advance if the currency is $ or its length is 1
      if (this.mieData === undefined) {
        this.mieData = []
        this.$store.commit('mutateMieAdvance', null)
      } else if (this.mieData.length === 1 || this.currency.id === 2) {
        this.mieData = []
        this.$store.commit('mutateMieAdvance', null)
      }
      // clean the mie intl advance if the currency is MZN or its length is 1
      if (this.mieIntlData === undefined) {
        this.mieIntlData = []
        this.$store.commit('mutateMieIntlAdvance', null)

      } else if (this.mieIntlData.length === 1 || this.currency.id === 1) {
        this.mieIntlData = []
        this.$store.commit('mutateMieIntlAdvance', null)
      }
      // clean the lodging advance
      if (this.lodgeData.length === 1) {
        this.lodgeData = []
        this.$store.commit('mutateLodgingAdvance', null)
      }
      // clean the other advance
      if (this.otherData.length === 1) {
        this.otherData = []
        this.$store.commit('mutateOtherAdvance', null)
      }
    },
    // move to the next page
    next () {
      if (this.mieData.length > 1 || this.mieIntlData.length > 1 || this.lodgeData.length > 1 || this.otherData.length > 1) {
        // remove all the advances that have the size of 1 (have only the totals row)
        this.cleanAdvances()
        this.$router.push('/edit-request-4')
      } else {
        // show the error
        // close the loader modal
        this.$refs.loaderRef.closeModal()
        // showing the dialog for the error
        this.processMessage = 'Fill atleast one advance to continue.'
        // open the error modal
        this.$refs.messageRef.openModal()
      }
    },
    // expands or collapses the main body
    expand (value) {
      this.expandBody = value
    },
    // submits the signature
    submit (filename) {
      const observable = ajax({
        url: process.env.VUE_APP_BASE_URL + '/signature/insertFile',
        method: 'POST',
        body: {
          employee_id: this.employee.id,
          filename: filename
        },
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      })

      const observer = {
        next: () => {
          // move to the profile page
          this.$router.push('/employee-profile')
        },
        error: () => {
          // close the loader modal
          this.$refs.loaderRef.closeModal()
          // showing the dialog for the error
          this.processMessage = 'Failed to submit the signature, please try again.'
          // open the error modal
          this.$refs.messageRef.openModal()
        },
        complete: () => {
          this.$refs.loaderRef.closeModal()
        }
      }

      observable.subscribe(observer)
    }
  }
}
</script>

<style>

#chart-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 40vh;
}

</style>
