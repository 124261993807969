<template>
    <Employee75Liquidations></Employee75Liquidations>
</template>
<script>
    import Employee75Liquidations from '@/components/EmployeeProcessed75Liquidations.vue'
    export default {
        name: 'employee-requests',
        components: {
            Employee75Liquidations
        }
    }
</script>