<template>
    <AdminPending75Liquidations></AdminPending75Liquidations>
</template>
<script>
    import AdminPending75Liquidations from '@/components/AdminPending75Liquidations.vue'
    export default {
        name: 'admin-track-Pending-request',
        components: {
            AdminPending75Liquidations
        }
    }
</script>