<template>
    <EmployeeUnprocessedRequests></EmployeeUnprocessedRequests>
</template>
<script>
    import EmployeeUnprocessedRequests from '@/components/EmployeeUnprocessedRequests.vue';
    export default {
        name: 'approved-unprocessed-requests',
        components: {
            EmployeeUnprocessedRequests
        }
    }
</script>