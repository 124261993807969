<template>
    <EmployeeProcessedRequests></EmployeeProcessedRequests>
</template>
<script>
    import EmployeeProcessedRequests from '@/components/EmployeeProcessedRequests.vue';
    export default {
        name: 'approved-unprocessed-requests',
        components: {
            EmployeeProcessedRequests
        }
    }
</script>