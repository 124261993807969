<template>
    <Employee25Liquidations></Employee25Liquidations>
</template>
<script>
    import Employee25Liquidations from '@/components/EmployeeUnprocessed25Liquidations.vue'
    export default {
        name: 'employee-requests',
        components: {
            Employee25Liquidations
        }
    }
</script>