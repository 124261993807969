<template>
    <AdminTrackLiquidation></AdminTrackLiquidation>
</template>
<script>
    import AdminTrackLiquidation from '@/components/AdminTrackApprovedLiquidation.vue'
    export default {
        name: 'Admin-track-liquidation',
        components: {
            AdminTrackLiquidation
        }
    }
</script>