<template>
    <ApprovedRequests></ApprovedRequests>
</template>
<script>
    import ApprovedRequests from '@/components/EmployeeApprovedRequests.vue'
    export default {
        name: 'approved-liquidations',
        components: {
            ApprovedRequests
        }
    }
</script>