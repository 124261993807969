<template>
  <div class="w-full h-screen bg-white overflow-x-hidden">
    <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
    <div>
      <Sidebar class="sidebar"/>
      <div :class="[!expandBody ? 'main px-5 space-y-4 margin' : 'main px-5 space-y-4']">
        <!-- the top route and username grid -->
        <div class="justify-between flex pt-6 text-size-md font-medium text-gray-500 w-full">
          <span><span class=" text-emerald-500">>Home</span> >Delegate Approvals</span>
          <span v-if="employee !== null">{{ employee.firstname }} {{ employee.surname }}</span>
        </div>
          <div>
          <!-- Timeline -->
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-4 mb-4 space-x-1 items-center">
              <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m16,1.5v5.5h-3s0-5.5,0-5.5c0-.828.672-1.5,1.5-1.5.829,0,1.5.671,1.5,1.5Zm7.157,6.564c-.808-.582-1.943-.411-2.647.294l-.51.51V3c0-.829-.671-1.5-1.5-1.5s-1.5.671-1.5,1.5v13.684c.874-.331,1.679-.838,2.352-1.512l4.062-4.062c.859-.859.773-2.305-.257-3.047Zm-8.157,2.436c0-.828-.672-1.5-1.5-1.5-.829,0-1.5.671-1.5,1.5v.5s0,0,0,0v7.5c0,1.379-1.122,2.5-2.5,2.5s-2.5-1.121-2.5-2.5v-9c0-.828-.672-1.5-1.5-1.5-.829,0-1.5.671-1.5,1.5v6.247l-.51-.51c-.704-.704-1.839-.876-2.647-.294-1.03.742-1.116,2.188-.257,3.047l4.062,4.062c1.247,1.248,3.088,1.948,4.852,1.948,3.033,0,5.5-2.468,5.5-5.5v-7.5h0v-.5Zm-4-2c0-.828-.672-1.5-1.5-1.5-.829,0-1.5.671-1.5,1.5v5.5h3s0-5.5,0-5.5Zm-1.5-3.5c.935,0,1.814.364,2.475,1.025.009.009.016.021.025.03v-2.435c0-.829-.671-1.5-1.5-1.5-.828,0-1.5.672-1.5,1.5v1.43c.165-.024.329-.051.5-.051Z"/>
              </svg>
              <p class="text-left text-size-md font-medium text-gray-600"> {{ employeedelegateData.length }} Delegated Approvals</p>
            </div>
            <div class="mx-8 mb-5 mt-5 border shadow px-4 py-6 grid grid-flow-row grid-cols-1 gap-x-4 gap-y-4 sm:gap-y-4">
              <!-- the button for adding approval delegates -->
              <div class="flex justify-between">
                <span></span>
                <div class="my-auto">
                  <button @click="deleteAllProxies()" class="shadow inline-block box-shadow text-size-sm px-20 py-2 bg-red-400  text-white font-medium leading-tight transform focus:bg-red-400 focus:outline-none focus:ring-0 active:bg-emerald-700 transition duration-150 ease-in-out">Delete Delagates</button>
                  <button @click="openModal()" class="shadow inline-block box-shadow text-size-sm px-20 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-700 focus:outline-none focus:ring-0 active:bg-emerald-700 transition duration-150 ease-in-out">Delegate Approavals</button>
                </div>
              </div>

              <div class=" flex">
                <input
                  v-model="filter"
                  type="text"
                  placeholder="Search..."
                  required class="shadow-sm bg-gray-50 text-gray-600 w-full text-size border px-2 py-3 border-gray-300  focus:outline-none focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 "/>
              </div>

              <!-- the delegates table -->
              <div class=" overflow-x-auto">
                <table id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                  <thead>
                    <tr class=" bg-emerald-600 text-size-xs pointer-events-none" style="">
                      <td class="py-2 px-2 text-left text-white font-bold">#</td>
                      <td class="py-2 px-2 text-left text-white font-bold">Employee</td>
                      <td class="py-2 px-2 text-left border-l text-white font-bold">Delegate</td>
                      <td class="py-2 px-2 border-l text-left text-white font-bold">Delegation Reason</td>
                      <td class="py-2 px-2 border-l text-left text-white font-bold">Start Date</td>
                      <td class="py-2 px-2 border-l text-left text-white font-bold">End Date</td>
                      <td class="py-2 px-2 border-l text-center text-white font-bold">Action</td>
                    </tr>
                  </thead>
                  <tbody v-if="dataLoaded">
                    <tr v-for="(data, i) in filteredRows" :key="i" class=" text-gray-600 text-size-sm" style="">
                      <td class="py-2 px-2 border text-left">{{ (i+1) }}</td>
                      <td class="py-2 px-2 border text-left">{{ data.owner_firstname }} {{ data.owner_surname }}</td>
                      <td class="py-2 px-2 border text-left">{{ data.delegate_firstname }} {{ data.delegate_surname }}</td>
                      <td class="py-2 px-2 border text-left">{{ data.english_reason }}</td>
                      <td class="py-2 px-2 border text-left">{{ data.start_date }}</td>
                      <td class="py-2 px-2 border text-left">{{ data.date_to }}</td>

                      <td  class="py-3 px-2 border text-center flex">
                        <div class="flex mx-auto space-x-4">
                          <svg @click="deletedelegate(data.id)" class="h-5 w-5 text-red-400 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.707,15.293-1.414,1.414L12,13.414,8.707,16.707,7.293,15.293,10.586,12,7.293,8.707,8.707,7.293,12,10.586l3.293-3.293,1.414,1.414L13.414,12Z"/></svg>
                          <!-- <svg @click="loadEditUnitBudgetModal(i)" class="h-5 w-5 text-emerald-600 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M23,24H5c-.55,0-1-.45-1-1s.45-1,1-1H23c.55,0,1,.45,1,1s-.45,1-1,1ZM2.48,11.89c-.31,.73-.48,1.53-.48,2.34v4.35l-1.71,1.71c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29l1.71-1.71H7.76c.81,0,1.61-.17,2.34-.48L2.48,11.89ZM20.91,7.83l-8.67,10.15c-.13,.15-.27,.29-.42,.42L3.59,10.17c.13-.14,.27-.28,.42-.41L14.17,1.1c1.78-1.54,4.47-1.45,6.14,.21l.39,.39c1.66,1.66,1.76,4.36,.22,6.13Z"/></svg> -->
                        </div>
                      </td>


                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="flex mb-5">
              <div class=" flex mx-auto items-center">
                <div v-if="startRow !== 0" @click="movePages(-1)" class=" bg-emerald-600 px-1 py-1 cursor-pointer hover:bg-emerald-700 focus:outline-none">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                  </svg>
                </div>
                <div v-if="(startRow+rowsPerPage) < employeedelegateData.length" @click="movePages(1)" class=" bg-emerald-600 px-1 py-1 cursor-pointer border-l hover:bg-emerald-700 focus:outline-none">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <!-- the delegates modal -->
     <TransitionRoot appear :show="isOpen" @close="closeModal()" as="template">
      <Dialog as="div">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 bg-black opacity-80" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="inline-block border w-full max-w-5xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
              >
                <DialogTitle
                  as="h4"
                  class="text-size font-medium leading-6 text-gray-600"
                >
                Delegate Approvals
                </DialogTitle>
                <div class="grid grid-flow-row grid-cols-2 grid-rows-2 gap-4 py-4">
                  <!-- employee selection -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Select the Delegate <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <div class=" w-full">
                        <CustomDropDown
                          :elementId="'delegateId'"
                          :url="'/employee/searchExcept'"
                          :persistedSelection="selectedEmployees"
                          :multipleSelection="true"
                          @selectedItems="(items) => {
                            splitParameters(items)
                            selectedEmployees = items
                          }"
                          :params="`&employeeId=${employee.id}`"
                          :display="{
                            id: 'id',
                            name: ['firstname', 'surname', 'position']
                          }"
                        />
                      </div>
                  </div>
                  </div>
                  <!-- employee selection -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Search & Select the Reason <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <div class=" w-full">
                        <CustomDropDown
                          :elementId="'reasonId'"
                          :url="'/delegation-reason/search'"
                          :persistedSelection="selectedReason"
                          :multipleSelection="false"
                          @selectedItems="(items) => {
                            selectedReason = items
                          }"
                          :display="{
                            id: 'id',
                            name: ['english_reason', 'portugese_reason']
                          }"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- the cost  -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Start Date <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="startDate"
                        required
                        placeholder="Item cost"
                        type="date"
                        class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                  <!-- unit quantity -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">End Date <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="endDate"
                        required
                        placeholder="Item quantity"
                        type="date"
                        class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                </div>
                <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                  <button
                    @click="closeModal()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Cancel
                  </button>
                  <button
                    @click="submitdelegate()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Save
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- the loader modal -->
    <Loader ref="loaderRef"/>
    <!-- the message loader -->
    <Message :title="title" :message="processMessage" ref="messageRef"/>
  </div>
</template>

<script>
import Navbar from '@/components/EmployeeNavbar.vue'
import Sidebar from '@/components/EmployeeSideBar.vue'
import { mapState } from 'vuex'
import { ajax } from 'rxjs/ajax'
import { retry, zip } from 'rxjs'
import { ref } from 'vue'
import Message from './modals/Message.vue'
import Loader from './modals/Loader.vue'
import CustomDropDown from './CustomDropDown.vue'
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue'


export default {
  computed: {
    ...mapState([
      'employee'
    ]),
    filteredRows () {
      return this.employeedelegateData.filter((row) => {
        const searchText = this.filter.toLowerCase()

        return JSON.stringify(row).toLowerCase().includes(searchText)
      }).slice(this.startRow, this.startRow + this.rowsPerPage)
    }
  },
  components: {
    Navbar,
    Sidebar,
    CustomDropDown,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    Loader,
    Message
  },
  setup () {
    const isOpen = ref(false)
    return {
      isOpen,
      closeModal () {
        isOpen.value = false
      },
      openModal () {
        isOpen.value = true
      }
    }
  },
  name: 'delegate-management',
  props: {
  },
  data: () => {
    return {
      filter: '',
      startRow: 0,
      rowsPerPage: 10,
      endPageItemIndex: 10,
      firstname: '',
      surname: '',
      email: '',
      company: {},
      location: {},
      showProfile: false,
      employeedelegateData: [],
      expandBody: false,
      selectedEmployee: {},
      selectedEmployees: {},
      selectedReason: {},
      dataLoaded: false,
      exceptionParams: '',
      BASE_URL: process.env.VUE_APP_BASE_URL ,
      startDate: '',
      endDate: '',
      title: 'Message',
      processMessage: ''
    }
  },
  mounted () {
    // removing the submenu items selection
    this.$store.commit('mutateActiveListItemIndex', 100)
    this.$store.commit('mutateSelectedSubMenuItem', 103)
    // get the employee details
    this.getEmployeeProxies()
  },
  methods: {
    movePages (amount) {
      var newStartRow = this.startRow + (amount * this.rowsPerPage)
      // only move if its greater than 0
      if (newStartRow >= 0 && newStartRow < this.employeedelegateData.length) {
        this.startRow = newStartRow
      }
    },
    // splits the exception parameters
    splitParameters (items) {
      const data = []
      const size = items.length
      for (let i = 0; i < size; i++) {
        const id = items[i].id
        data.push(id)
      }
      this.exceptionParams = data.toString()
    },
    deletedelegate (id) {
      // close the delegates modal
      this.closeModal()

      // open the loading modal
      this.$refs.loaderRef.openModal()

      const observable = ajax({
        url: this.BASE_URL + '/delegation/delete?id=' + id,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      })
      const observer = {
        next: () => {
          // this.isOk = true
          // this.messageTitle = 'Message'
          // this.message = 'Successfully deleted the delegation details..'
          // this.$refs.messageModal.openMessageModal()
        },
        error: () => {
          // close the loader modal
          this.$refs.loaderRef.closeModal()

          // showing the dialog for the error
          this.processMessage = 'Failed to submit the delagation details, please try again.'
          // open the error modal
          this.$refs.messageRef.openModal()
        },
        complete: () => {
          this.$refs.loaderRef.closeModal()
          this.getEmployeeProxies()
        }
      }
      
      observable.subscribe(observer)
    },

    deleteAllProxies () {
      // close the delegates modal
      this.closeModal()

      // open the loading modal
      this.$refs.loaderRef.openModal()

      const observable = ajax({
        url: this.BASE_URL + `/delegation/deleteByOwnerId?employeeId=${this.employee.id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      })
      const observer = {
        next: () => {
          // this.isOk = true
          // this.messageTitle = 'Message'
          // this.message = 'Successfully deleted the delegation details..'
          // this.$refs.messageModal.openMessageModal()
        },
        error: () => {
          // close the loader modal
          this.$refs.loaderRef.closeModal()

          // showing the dialog for the error
          this.processMessage = 'Failed to submit the delagation details, please try again.'
          // open the error modal
          this.$refs.messageRef.openModal()
        },
        complete: () => {
          this.$refs.loaderRef.closeModal()
          this.getEmployeeProxies()
        }
      }
      
      observable.subscribe(observer)
    },
    submitdelegate () {
      // get the submitted details put them into observables then insert them send them to the back-end
      // get the selected employees then put them into diffrent observables
      if (this.startDate !== '' && this.endDate !== null && this.selectedReason.name !== null && this.selectedReason !== undefined && Array.isArray(this.selectedEmployees)) {
        const size = this.selectedEmployees.length
        var observables = []

        // close the delegates modal
        this.closeModal()

        // open the loading modal
        this.$refs.loaderRef.openModal()

        // generating the observable
        for (let i = 0; i < size; i++) {
          const delegateId = this.selectedEmployees[i].id

          const delegateObservable = ajax({
            url: this.BASE_URL + '/delegation/insert',
            method: 'POST',
            headers: {
              Authorization: `Bearer ${this.employee.token}`
            },
            body: {
              owner_id: this.employee.id,
              delegate_id: delegateId,
              reason_id: this.selectedReason.id,
              start_date: this.startDate,
              date_to: this.endDate
            }
          })

          observables.push(delegateObservable)
        }

        const observable = zip(...observables)

        const observer = {
          next: () => {
             // showing the dialog for the error
             this.processMessage = 'Successfully Submitted the delegation details.'
              // open the error modal
              this.$refs.messageRef.openModal()
          },
          error: () => {
            // close the loader modal
            this.$refs.loaderRef.closeModal()

            // showing the dialog for the error
            this.processMessage = 'Failed to submit the delagation details, please try again.'
            // open the error modal
            this.$refs.messageRef.openModal()
          },
          complete: () => {
            // close the loading modal
            this.$refs.loaderRef.closeModal()
            this.getEmployeeProxies()
          }
        }

        observable.subscribe(observer)
      }
    },
    // expands or collapses the main body
    expand (value) {
      this.expandBody = value
    },
    // getting the employee details
    getEmployeeProxies () {
      this.dataLoaded = false
      const ownerId = this.employee.id
      const observable = ajax({
        url: process.env.VUE_APP_BASE_URL + `/delegation/findByOwnerId?ownerId=${ownerId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      }).pipe(
        retry(1)
      )
      const observer = {
        next: (response) => {
          const data = response.response
          this.employeedelegateData = data
          this.dataLoaded = true
        },
        error: (err) => {
          const status = err.status
          if (status === 401) {
            this.$router.push('/')
          }
        },
        complete: () => {
          this.showProfile = true
        }
      }
      observable.subscribe(observer)
    }
  }
}
</script>

<style>

#chart-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 40vh;
}

</style>
