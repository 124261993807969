<template>
    <AdminTrackRequest></AdminTrackRequest>
</template>
<script>
    import AdminTrackRequest from '@/components/AdminTrackApprovedRequest.vue'
    export default {
        name: 'admin-track-approved-request',
        components: {
            AdminTrackRequest
        }
    }
</script>