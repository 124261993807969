<template>
    <div>
      <TransitionRoot appear :show="isMessageOpen" @close="closeSignatureModal" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0 bg-black opacity-70" />
              </TransitionChild>
  
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
  
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="inline-block border w-full max-w-4xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
                >
                  <DialogTitle
                    as="h4"
                    class="text-size font-medium leading-6 text-gray-600"
                  >
                    Signature Upload <span class=" text-size-sm text-emerald-600">(Upload Below)</span>
                  </DialogTitle>
                  <div class="my-4 justify-center w-full flex">
                    <div class="shadow cursor-pointer inline-block box-shadow text-size-sm px-24 py-2.5 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                      <form
                        id="form-2"
                        :action="`${BASE_URL}/signature/upload`">
                        Click Here To Select & Upload a Files
                      </form>
                    </div>
                  </div>
                  <div class="mt-2 flex justify-center space-x-0.5">
                    <button
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size-sm text-emerald-900 bg-gray-300 border
                              border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                              focus-visible:ring-offset-2 focus-visible:ring-white"
                      @click="closeSignatureModal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
      <!-- the loader modal -->
      <Loader ref="loaderRef"/>
      <!-- the message loader -->
      <Message :title="title" :message="processMessage" ref="messageRef"/>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue'
  import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue'
  import { ajax } from 'rxjs/ajax';
  import Loader from './Loader.vue';
  import Message from './Message.vue';
  import { mapState } from 'vuex';
  import Dropzone from 'dropzone';

  export default {
    computed: {
      ...mapState([
        'employee'
      ])
    },
    mounted () {
    },
    components: {
      TransitionRoot,
      TransitionChild,
      Dialog,
      DialogOverlay,
      // DialogTitle
      Loader,
      Message
    },
    setup () {
      const isMessageOpen = ref(false)
      return {
        isMessageOpen,
        closeSignatureModal () {
          isMessageOpen.value = false
        },
        openSignatureModal () {
          // the second dropzone
          setTimeout(() => {
            const myDropzone2 = new Dropzone('#form-2', {
              headers: { Authorization: `Bearer ${this.employee.token}` },
              disablePreviews: true
            })
            
            // callback for the file added
            myDropzone2.on('addedfile', (file) => {
              // open the loading modal
              if (file.type.includes('png') || file.type.includes('jpg')  || file.type.includes('jpeg')) {
                this.$refs.loaderRef.openModal()
              } else {
                myDropzone2.removeFile(file);
              }
            })
            
            // the second drop zone
            myDropzone2.on('complete', (file) => {
              const fileDetails = JSON.parse(file.xhr.response)
              // const realFilename = fileDetails.realName
              const renamedFilename = fileDetails.renamedFilename
              
              // insert the signaturev dcetails
              this.submit(renamedFilename)
            })
            
            myDropzone2.on("error", () => {
              // close the loader modal
              this.$refs.loaderRef.closeModal()
              // showing the dialog for the error
              this.processMessage = 'The submission failed, please make sure the file is an image (JPG/PNG/JPEG).'
              // open the error modal
              this.$refs.messageRef.openModal()
            });

          }, 600)
          isMessageOpen.value = true
        }
      }
    },
    name: 'message-modal',
    props: {
    },
    data: () => {
      return {
        title: 'Error Message',
        processMessage: '',
        base64String: null,
        BASE_URL: process.env.VUE_APP_BASE_URL
      }
    },
    methods: {
      emitEvent () {
        this.$emit('submitted', 'reload')
      },
      // submits the signature
      submit (filename) {
        const observable = ajax({
          url: process.env.VUE_APP_BASE_URL + '/signature/insertFile',
          method: 'POST',
          body: {
            employee_id: this.employee.id,
            filename: filename
          },
          headers: {
            Authorization: `Bearer ${this.employee.token}`
          }
        })

        const observer = {
          next: () => {
            this.closeSignatureModal()
            // close the loader modal
            // emit the event
            this.emitEvent()
            setTimeout (() => {
              this.$refs.loaderRef.closeModal()
              // showing the dialog for the error
              this.title = 'Message'
              this.processMessage = 'Successfully submitted the signature.'
              // open the error modal
              this.$refs.messageRef.openModal()
            }, 400)
          },
          error: () => {
            // close the loader modal
            this.$refs.loaderRef.closeModal()
            // showing the dialog for the error
            this.processMessage = 'Failed to submit the signature, please try again.'
            // open the error modal
            this.$refs.messageRef.openModal()
          },
          complete: () => {
            this.$refs.loaderRef.closeModal()
          }
        }

        observable.subscribe(observer)
      },
      // submit base 64 signature
      //submit () {
        // get the base64 and emit the event
        // const isEmpty = this.$refs.signature.isEmpty()
        // if (!isEmpty) {
        //   this.base64String = this.$refs.signature.save()
        //   this.emitEvent()

        //   // close the modal
        //   this.closeSignatureModal()
        // }
      // },
      // submit () {
      //   const isEmpty = this.$refs.signature.isEmpty()
      //   if (!isEmpty) {
      //       // open the loading modal
      //       this.$refs.loaderRef.openModal()
            
      //       var signature = this.$refs.signature.save()

      //       const observable = ajax({
      //         url: process.env.VUE_APP_BASE_URL + '/signature/insert',
      //         method: 'POST',
      //         body: {
      //           employee_id: this.employee.id,
      //           filename: signature
      //         },
      //         headers: {
      //           Authorization: `Bearer ${this.employee.token}`
      //         }
      //       })

      //       const observer = {
      //         next: () => {
      //           this.title = 'Message',
      //           // close the loader modal
      //           this.$refs.loaderRef.closeModal()
      //           this.closeSignatureModal()
      //           // showing the success modal

      //           setTimeout (() => {
      //             this.processMessage = 'Successfully submitted your signature.'
      //             // open the error modal
      //             this.$refs.messageRef.openModal()
      //           }, 800)
      //         },
      //         error: () => {
      //           // close the loader modal
      //           this.$refs.loaderRef.closeModal()
      //           this.closeSignatureModal()
      //           // showing the dialog for the error
      //           this.processMessage = 'Failed to submit the signature, please try again.'
      //           // open the error modal
      //           this.$refs.messageRef.openModal()
      //         },
      //         complete: () => {
      //           this.$refs.loaderRef.closeModal()
      //         }
      //       }

      //       observable.subscribe(observer)
      //   } else {
      //     this.processMessage = 'Failed to submit the signature, you cannot submit an empty signature.'
      //     // open the error modal
      //     this.$refs.messageRef.openModal()
      //   }
      // },
      // removes the signature
      clearSignature () {
        this.$refs.signature.clear()
      },
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style >
  </style>
  