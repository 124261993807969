<template>
    <AdminRejected75Liquidations></AdminRejected75Liquidations>
</template>
<script>
    import AdminRejected75Liquidations from '@/components/AdminRejected75Liquidations.vue'
    export default {
        name: 'admin-track-Rejected-request',
        components: {
            AdminRejected75Liquidations
        }
    }
</script>