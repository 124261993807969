<template>
  <div class="w-full h-screen bg-white overflow-x-hidden">
    <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
    <div>
      <Sidebar class="sidebar"/>
      <div :class="[!expandBody ? 'main px-5 space-y-2 margin mb-16' : 'main px-5 space-y-2 mb-16']">
        <!-- the top route and username grid -->
        <div class="justify-between flex pt-5 text-size-md font-medium text-gray-500 w-full">
          <span class=" text-emerald-500" >> Home > Track Liquidation</span>
          <span v-if="employee !== null">{{ employee.firstname }} {{ employee.surname }} </span>
        </div>
        <!-- the requests charts -->
        <div class="grid gap-6 grid-rows-1 grid-cols-1">
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg mt-4 bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-6 mb-4 items-center space-x-1">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,24H3a3,3,0,0,1-3-3V0H2V21a1,1,0,0,0,1,1H24Z"/><rect x="14" y="11" width="2" height="9"/><rect x="6" y="11" width="2" height="9"/><rect x="18" y="6" width="2" height="14"/><rect x="10" y="6" width="2" height="14"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m12,12c3.309,0,6-2.691,6-6S15.309,0,12,0s-6,2.691-6,6,2.691,6,6,6Zm0-10c2.206,0,4,1.794,4,4s-1.794,4-4,4-4-1.794-4-4,1.794-4,4-4Zm9,17v5h-2v-5c0-1.654-1.346-3-3-3h-2l-1.333,2,1.333,6h-4l1.333-6-1.333-2h-2c-1.654,0-3,1.346-3,3v5h-2v-5c0-2.757,2.243-5,5-5h8c2.757,0,5,2.243,5,5Z"/>
              </svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M18.4,7.379a1.128,1.128,0,0,1-.769-.754h0a8,8,0,1,0-15.1,5.237A1.046,1.046,0,0,1,2.223,13.1,5.5,5.5,0,0,0,.057,18.3,5.622,5.622,0,0,0,5.683,23H11a1,1,0,0,0,1-1h0a1,1,0,0,0-1-1H5.683a3.614,3.614,0,0,1-3.646-2.981,3.456,3.456,0,0,1,1.376-3.313A3.021,3.021,0,0,0,4.4,11.141a6.113,6.113,0,0,1-.073-4.126A5.956,5.956,0,0,1,9.215,3.05,6.109,6.109,0,0,1,9.987,3a5.984,5.984,0,0,1,5.756,4.28,2.977,2.977,0,0,0,2.01,1.99,5.934,5.934,0,0,1,.778,11.09.976.976,0,0,0-.531.888h0a.988.988,0,0,0,1.388.915c4.134-1.987,6.38-7.214,2.88-12.264A6.935,6.935,0,0,0,18.4,7.379Z"/><path d="M18.707,16.707a1,1,0,0,0,0-1.414l-1.586-1.586a3,3,0,0,0-4.242,0l-1.586,1.586a1,1,0,0,0,1.414,1.414L14,15.414V23a1,1,0,0,0,2,0V15.414l1.293,1.293a1,1,0,0,0,1.414,0Z"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m17,0H7C4.243,0,2,2.243,2,5v14c0,2.757,2.243,5,5,5h10c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5ZM7,2h10c1.654,0,3,1.346,3,3H4c0-1.654,1.346-3,3-3Zm10,20H7c-1.654,0-3-1.346-3-3V7h16v12c0,1.654-1.346,3-3,3Zm0-11c0,.553-.448,1-1,1h-8c-.552,0-1-.447-1-1s.448-1,1-1h8c.552,0,1,.447,1,1Zm-4,4c0,.553-.448,1-1,1h-4c-.552,0-1-.447-1-1s.448-1,1-1h4c.552,0,1,.447,1,1Z"/></svg> -->
              <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m13,5v2H5v-2h8Zm-7,6h6v-2h-6v2Zm17,4.764v5.236c0,1.654-1.346,3-3,3H1V0h17c1.654,0,3,1.346,3,3v8.764l2,4ZM3,22h12V2H3v20Zm18-5.764l-2-4V3c0-.551-.448-1-1-1h-1v20h3c.552,0,1-.449,1-1v-4.764Z"/>
              </svg>
              <p class="text-left text-size-md font-medium text-gray-600">Track the Liquidation</p>
            </div>
            <!--  track the request -->
            <div class="ml-8 pb-8 mt-4">
              <div
                class="ring-1 text-left cursor-pointer ring-gray-200 shadow-md mr-8 pl-1 chart-height"
              >
              <div class=" flex sm:justify-start pl-8 overflow-x-auto">
                    <div class="flex px-4 space-x-8">
                      <!-- {{ trail }} -->
                      <div class="flex-1 py-6 mt-1">
                        <!-- looping through the approvers -->
                        <!-- <div v-for="(data, i) in trail" :key="i"> -->
                        <div v-for="(data, i) in trailData.length" :key="i">
                          <!-- approval icon -->
                          <svg v-if="data.status == 'Approved' || data.status == 'Submitted'" class="h-5 w-5 text-emerald-600 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm-.091,15.419c-.387.387-.896.58-1.407.58s-1.025-.195-1.416-.585l-2.782-2.696,1.393-1.437,2.793,2.707,5.809-5.701,1.404,1.425-5.793,5.707Z"/></svg>
                          <!-- rejection icon -->
                          <svg v-else-if="data.status == 'Denied'" class="h-5 w-5 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.707,15.293-1.414,1.414L12,13.414,8.707,16.707,7.293,15.293,10.586,12,7.293,8.707,8.707,7.293,12,10.586l3.293-3.293,1.414,1.414L13.414,12Z"/></svg>
                          <!-- pending icon -->
                          <svg v-else class="h-5 w-5 text-emerald-600 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,16c-2.209,0-4-1.791-4-4s1.791-4,4-4,4,1.791,4,4-1.791,4-4,4Z"/></svg>
                          <!-- {{ (i) }} {{ (trail.length - 1) }} -->
                          <!-- the line -->
                          <div v-if=" i < (trailData.length - 1)" class=" w-0.5 h-52 bg-emerald-500 ml-2 my-0.5"></div>
                          <!-- <svg class="h-5 w-5 text-emerald-400 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,16c-2.209,0-4-1.791-4-4s1.791-4,4-4,4,1.791,4,4-1.791,4-4,4Z"/></svg> -->
                          <!-- the line -->
                          <!-- <div class=" w-0.5 h-48 bg-emerald-400 ml-2 my-0.5"></div> -->
                          <!-- <svg class="h-5 w-5 text-emerald-400 fill-current" xmlns="http://www.w3.org/2000/svg" id="arrow-circle-down" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,16a2.993,2.993,0,0,1-1.987-.752c-.327-.291-.637-.574-.84-.777L6.3,11.647a1,1,0,0,1,1.4-1.426L10.58,13.05c.188.187.468.441.759.7a1,1,0,0,0,1.323,0c.29-.258.57-.512.752-.693L16.3,10.221a1,1,0,1,1,1.4,1.426l-2.879,2.829c-.2.2-.507.48-.833.769A2.99,2.99,0,0,1,12,16Z"/></svg> -->
                          <!-- <svg class="h-5 w-5 text-emerald-400 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,16c-2.209,0-4-1.791-4-4s1.791-4,4-4,4,1.791,4,4-1.791,4-4,4Z"/></svg> -->
                          <!-- the line -->
                          <!-- <div class=" h-0.5 w-80 bg-emerald-400"></div> -->
                        </div>
                      </div>
                      <div v-if="trailData.length > 0" class="flex-1 py-4">
                        <!-- <div v-for="(data, i) in trail" :key="i"> -->
                        <div v-for="(data, i) in trailData" :key="i">
                          <div :class="i==0 ? 'py-3 px-2 text-size-md mt-3 shadow-md hover:shadow-md border border-gray-200' : 'py-3 px-2 text-size-md shadow-md hover:shadow-md border border-gray-200 my-20'" style="width: 38rem">
                            <div class="mx-2 w-full text-center text-gray-400 space-y-2 grid">
                              <div class="space-x-1.5 text-left w-full items-center mt-1.5 justify-between flex">
                                <div v-if="data.surname !== null">
                                  <span class="font-medium text-gray-500 w-20 mr-6">Name:</span>
                                  <span class="w-60">{{ data.firstname }} {{ data.surname }}</span>
                                </div>
                                <div v-else>
                                  <span class="font-medium text-gray-500 w-20 mr-6">Name:</span>
                                  <span class="w-60">{{ data.names }}</span>
                                </div>
                                <!-- <svg class="h-4 w-4 -translate-x-6 fill-emerald-700" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m8.009,23h-.006c-.803-.002-1.557-.316-2.123-.887L.311,16.502l1.42-1.408,5.569,5.611c.188.189.439.295.707.295h.002c.267,0,.519-.104.708-.293l13.577-13.577,1.414,1.414-13.577,13.577c-.567.567-1.32.879-2.122.879Zm2.14-11.897L18.349,2.902l-1.414-1.414-8.2,8.2c-.19.191-.404.307-.713.293-.269-.001-.521-.108-.709-.301L3.235,5.516l-1.429,1.399,4.077,4.165c.565.577,1.32.897,2.127.902h.017c.801,0,1.554-.312,2.121-.879Z"/></svg> -->
                                <!-- {{ data.status }} -->
                                <svg :class=" data.status !== 'Denied' && data.status !== 'Pending'  ? 'h-4 w-4 -translate-x-6 fill-emerald-700' : 'hidden'" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m6.01,11.26L1.923,7.044l2.154-2.088,3.713,3.831L16.039.539l2.121,2.121-8.607,8.607c-.472.472-1.104.732-1.772.732-.67-.001-1.298-.264-1.77-.739Zm15.387-5.08l-13.611,13.611-5.221-5.266-2.13,2.113,5.574,5.62c.472.476,1.1.738,1.77.74.667,0,1.301-.26,1.773-.732l13.966-13.966-2.121-2.121Z"/></svg>
                                <svg :class=" data.status === 'Denied'  ? 'h-4 w-4 -translate-x-6 fill-red-500' : 'hidden'" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12h12V12C24,5.383,18.617,0,12,0Zm9,21H12c-4.963,0-9-4.037-9-9S7.037,3,12,3s9,4.037,9,9v9Zm-4.189-11.689l-2.689,2.689,2.689,2.689-2.121,2.121-2.689-2.689-2.689,2.689-2.121-2.121,2.689-2.689-2.689-2.689,2.121-2.121,2.689,2.689,2.689-2.689,2.121,2.121Z"/></svg>
                                <svg :class=" data.status === 'Pending'  ? 'h-4 w-4 -translate-x-6 fill-red-300' : 'hidden'" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                                  <path d="m11.923,17.806c.108.268.076.572-.085.811-.162.24-.431.383-.72.383h-2.131s-2.132,0-2.132,0c-.289,0-.558-.144-.72-.383-.161-.239-.193-.543-.085-.811.367-.908,1.073-1.781,2.099-2.592l.3-.237c.316-.25.765-.249,1.08.002l.294.235c1.027.816,1.734,1.689,2.1,2.593Zm4.93,2.218c-.681-.471-1.615-.303-2.087.38-.259.374-.683.596-1.135.596H4.368c-.401,0-.782-.176-1.046-.481-.147-.172-.385-.537-.308-1.048.337-2.244,1.746-4.361,4.188-6.294.359-.285.569-.718.569-1.176s-.21-.892-.569-1.176c-2.442-1.933-3.852-4.051-4.188-6.295-.077-.511.16-.875.308-1.047.264-.306.645-.481,1.046-.481h9.264c.4,0,.781.174,1.044.479.54.626,1.486.697,2.115.156.628-.541.697-1.488.156-2.116-.833-.966-2.042-1.52-3.315-1.52H4.368C3.093,0,1.883.556,1.049,1.524.227,2.479-.139,3.737.048,4.975c.382,2.542,1.704,4.899,3.936,7.025C1.752,14.126.43,16.483.048,19.025c-.187,1.238.179,2.496,1.001,3.451.834.969,2.044,1.524,3.319,1.524h9.264c1.437,0,2.783-.706,3.602-1.889.472-.681.302-1.616-.38-2.087Zm7.146-8.024c0,3.314-2.686,6-6,6s-6-2.686-6-6,2.686-6,6-6,6,2.686,6,6Zm-3.5,1.086l-1.5-1.5v-2.586c0-.552-.448-1-1-1h0c-.552,0-1,.448-1,1v2.586c0,.53.211,1.039.586,1.414l1.5,1.5c.391.39,1.024.39,1.414,0,.391-.391.391-1.024,0-1.414Z"/>
                                </svg>
                                <!-- <svg :class=" data.status !== 'Pending' ? 'h-4 w-4 -translate-x-6 fill-emerald-700' : 'h-4 w-4 -translate-x-6 fill-gray-400'" xmlns="http://www.w3.org/2000/svg" id="Isolation_Mode" data-name="Isolation Mode" viewBox="0 0 24 24" width="512" height="512"><path d="M7.8,21.425A2.542,2.542,0,0,1,6,20.679L.439,15.121,2.561,13,7.8,18.239,21.439,4.6l2.122,2.121L9.6,20.679A2.542,2.542,0,0,1,7.8,21.425Z"/></svg> -->
                              </div>
                              <div v-if="i==0" class="space-x-1 text-left w-full flex items-center mt-1.5">
                                <span class="font-medium text-gray-500 w-14">Reason:</span>
                                <span class="w-60">{{ data.reason.substring(0, 32) }}...</span>
                              </div>
                              
                              <div class="space-x-1 text-left w-full flex items-center mt-1.5">
                                <span class="font-medium text-gray-500 w-14">Position:</span>
                                <span class="w-60">{{ data.position }}</span>
                              </div>

                              <div class="space-x-1 text-left w-full flex items-center mt-1.5">
                                <span class="font-medium text-gray-500 w-14">Date:</span>
                                <span class="w-60">{{ data.date }}</span>
                              </div>

                              <div class="space-x-1 text-left w-full flex items-center mt-1.5">
                                <span class="font-medium text-gray-500 w-14">Status:</span>
                                <span class="w-60 ">{{ data.status }}</span>
                              </div>

                              <div v-if="i!==0 && data.feedback!==''" class="space-x-1 text-left w-full flex items-center mt-1.5">
                                <span class="font-medium text-gray-500 w-14">Feedback :</span>
                                <span class="w-60 ">{{ data.feedback }}</span>
                              </div>
                              
                            </div>
                          </div>
                      </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- the loader modal -->
    <Loader ref="loaderRef"/>
    <!-- the message loader -->
    <Message :title="title" :message="processMessage" ref="messageRef"/>
  </div>
</template>

<script>
import Navbar from '@/components/EmployeeNavbar.vue'
import Sidebar from '@/components/EmployeeSideBar.vue'
import { mapState } from 'vuex'
import { ajax } from 'rxjs/ajax'
import Message from './modals/Message.vue';
import Loader from './modals/Loader.vue';

export default {
  computed: {
    ...mapState([
      'employee'
    ])
  },
  components: {
    Navbar,
    Sidebar,
    Loader,
    Message
  },
  setup () {
    return {
    }
  },
  name: 'admin-home',
  props: {
  },
  data: () => {
    return {
      trailData: []
    }
  },
  mounted () {
    // removing the submenu items selection
    if (this.employee !== null) {
      // this.$store.commit('mutateActiveListItemIndex', 200)
      // this.$store.commit('mutateSelectedSubMenuItem', 201)
      // get the request
      this.getLiquidationTrail()
    }
  },
  methods: {
    // getting the pending activity approvals
    getLiquidationTrail () {
      const liquidationId = localStorage.getItem('liquidationId')
      this.showRequests = false
      const observable = ajax({
        url: process.env.VUE_APP_BASE_URL + `/liquidation-approval/findTrail?id=${liquidationId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      })
      const observer = {
        next: (response) => {
          const data = response.response
          this.trailData = data
        },
        error: (err) => {
          const status = err.status
          if (status === 401) {
            this.$router.push('/')
          }
        },
        complete: () => {}
      }
      observable.subscribe(observer)
    },
    // file size converter
    fileSizeFormatter (bytes) {
      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) {
        return '0 Bytes'
      }
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
    },
    // switch the view
    switchView (index) {
      this.documentsView[(index - 1)] = true
      this.currentView = index
    },
    // date difference in days
    dateDiff (startDate, endDate) {
      const date2 = new Date(endDate)
      const date1 = new Date(startDate)

      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      // remove the time and time-zone details.
      const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
      const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

      return (Math.floor((utc2 - utc1) / _MS_PER_DAY) + 1);
    },
    // format the table data
    formatData (data) {
      var formattedData
      // check if it is a number
      if (data !== null && data !== undefined && !isNaN(data) && String(data).length > 2) {
        // format the number to a currency format
        var currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'MWK' })
        formattedData = currencyFormat.format(String(data)).substring(4)
      } else if (data !== null && data !== undefined && data.toString().includes('-') && !isNaN(Date.parse(data))) {
        // check if the data is a date
        // formating the dates supplied
        var textDate = new Date(data)
        var formattedTextDate = textDate.getDate() + ' ' + this.months[textDate.getMonth()] + ' ' + textDate.getFullYear()
        formattedData = formattedTextDate
      } else {
        formattedData = data
      }
      return formattedData
    },
    // calculates the date difference in days for the schedule days
    dateDiffInDays () {
        // the date which the activity starts
        const days = []
        const scheduleSize = this.scheduleData.length

        for (let i = 0; i < scheduleSize; i++) {
          const startDate = this.scheduleData[i].startDate
          days.push(startDate)
        }

        // sort the days and get the minimum day
        days.sort()
        const miniDate = days[0]
        const dateFrom = new Date(miniDate)


        // today's date
        const dateTo = new Date()

        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // remove the time and time-zone details.
        const utc1 = Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate());
        const utc2 = Date.UTC(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
      },
    // move to the next page
    back () {
      this.$router.push('/create-request-5')
    },
    // expands or collapses the main body
    expand (value) {
      this.expandBody = value
    },
    // submits the signature
    submit () {
      if (!this.documentsView.every(value => value === true)) {
        var message = 'Before resubmitting your request: \n'
        if (this.documentsView[1] === false) {
          message += '  - Please view & verify your Hotel Need. \n'
        }
        if (this.documentsView[2] === false) {
          message += '  - Please view & verify your Advances. \n'
        }
        if (this.documentsView[3] === false) {
          message += '  - Please view  & verify the Terms of Reference. \n'
        }
        if (this.documentsView[4] === false) {
          message += '  - Please view  & verify the Uploaded Files. \n'
        }
        message += 'Click on the buttons above to view & verify the entered details.'
        this.processMessage = message
        // open the error modal
        this.$refs.messageRef.openModal()
      } else {
        // open the loading modal
        this.$refs.loaderRef.openModal()
        const advanceData = {
          advance: this.advance,
          hotelNeed: this.hotelNeed,
          mie: this.mieAdvance,
          hotel: this.hotel,
          lodging: this.lodgingAdvance,
          other: this.otherAdvance,
          tor: this.tor
        }

        const observable = ajax({
          url: process.env.VUE_APP_BASE_URL + '/request/insert',
          method: 'POST',
          body: {
            employee_id: this.employee.id,
            attachments: JSON.stringify(this.requestAttachments),
            advance: JSON.stringify(advanceData)
          },
          headers: {
            Authorization: `Bearer ${this.employee.token}`
          }
        })

        const observer = {
          next: () => {
            // move to the profile page
            this.$router.push('/employee-requests')
          },
          error: () => {
            // close the loader modal
            this.$refs.loaderRef.closeModal()
            // showing the dialog for the error
            this.processMessage = 'Failed to submit the signature, please try again.'
            // open the error modal
            this.$refs.messageRef.openModal()
          },
          complete: () => {
            this.$refs.loaderRef.closeModal()
            
          }
        }

        observable.subscribe(observer)
      }
    }
  }
}
</script>

<style>

#chart-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 40vh;
}

</style>
